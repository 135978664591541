import React, {useContext, useState, useEffect, createRef} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { UserContext } from "../Context/UserContext";
import { uploadProfilePic, updateProfile, addSocial, updateSocial } from "../Services/user.service";
import user from '../Assets/user.png';
import edit from '../Assets/edit.png';
import facebookIcon from '../Assets/facebook.png';
import instagramIcon from '../Assets/instagram.png';
import twitterIcon from '../Assets/twitter.png';
import tiktokIcon from '../Assets/tiktok.png';
import snapchatIcon from '../Assets/snapchat.png';
import youtubeIcon from '../Assets/youtube.png';
import logoPlain from '../Assets/logo.png';
import www from '../Assets/www.png';
import {showNotif, is_url, handleImgChange} from '../Component/Utils';
import { LoopCircleLoading } from 'react-loadingg';
import CropImg from '../Component/CropImg';
import close from '../Assets/close.png';

const UsersProfile = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [editProfile, setEditProfile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [qcm, setQcm] = useState(false);
    const [editSocial, setEditSocial] = useState(false);
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [youtube, setYoutube] = useState('');
    const [merch, setMerch] = useState('');
    const [snapchat, setSnapchat] = useState('');
    const [username, setUsername] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
    const inputOpenFileRef = createRef();
    const [crop, setCrop] = useState({aspect: 1, width: 300});
    const [cropPic, setCropPic] = useState(null);
    const [croppedImage, setCroppedImage] = useState('');
    const [imageRef, setImageRef] = useState('');
    const [pass, setPass] = useState('');
    const [confPass, setConfPass] = useState('');
    const employeesLabel = [
        {val: 0, label: '0'},
        {val: 1, label: '1 to 10'},
        {val: 11, label: '11 to 50'},
        {val: 51, label: 'More than 50'},
    ];
    const turnLabel = [
        {val: 10000, label: '$10K"'},
        {val: 100000, label: '$100K'},
        {val: 1000000, label: '$1M'},
        {val: 1000001, label: 'More than $1M'},
    ];

    useEffect(() => {
        if (!userState || !userState.token)
            props.history.push('/login');
    }, [userState]);

    useEffect(() => {
        if (croppedImage) {
            setCropPic(false);
            uploadPic();
        }
    }, [croppedImage]);

    async function uploadPic() {
        if (!croppedImage)
            showNotif(true, 'Error', 'Image not saved.');
        else {
            setLoading(true);
            let res = await uploadProfilePic(croppedImage, userState.token, userState.theArtist.artist._id);
            setLoading(false);
            if (res) {
                userState.theArtist.artist.picture = res;
                setUserState({...userState});
                setLoading(false);;
            }
        }
    }

    function calculPastEvt() {
        let nb = 0;
        let eventDate;
        for (let i = 0; i < userState.theArtist.artist.events.length; i++) {
            eventDate = new Date(parseFloat(userState.theArtist.artist.events[i].date) * 1000);
            if (userState.theArtist.artist.events[i].state === 'OVER'|| eventDate < new Date().setMinutes(new Date().getMinutes() - 30))
                nb += 1;
        }
        return (nb);
    }

    function checkError() {
        let err = true;
        // var regex = /[A-Z]/;

        if (!description || !username || !pass) {
            showNotif(true, 'Error', 'Please enter the name, password and description.');
            err = false;
        }
        // if (pass && (pass.length < 8 || !regex.test(pass))) {
        //     showNotif(true, 'Error password', 'Password must be at least 8 characters and it must contains at least 1 capital letter.');
        //     err = false;
        // }
        return err;
    }

    async function updateInfo() {
        if (checkError()) {
            setLoading(true);
            let res = await updateProfile(description, username, userState.theArtist.artist._id, userState.token, pass);
            setLoading(false);
            if (res) {
                userState.theArtist.artist.name = username;
                userState.theArtist.username = username;
                userState.theArtist.artist.description = description;
                setUserState({...userState});
                setEditProfile(false);
            }
        }
    }

    async function updateSocialMedia(name, url) {
        let res;
        let idx = userState.theArtist.artist.socialMedias.findIndex(elem => elem.name === name);
        if (url && !is_url(url)) {
            showNotif(true, 'Error', 'Please enter a valid social media link.');
            return ;
        }
        setLoading(true);
        if (idx >= 0) {
            if (userState.theArtist.artist.socialMedias[idx].url === url)
                {
                    showNotif(true, 'Error', 'Please enter a new link.');
                    setLoading(false);
                    return ;
                }
            res = await updateSocial(name, url, userState.theArtist.artist.socialMedias[idx]._id, userState.token, userState.theArtist.artist._id);
        }
        else
            res = await addSocial(name, url, userState.token, userState.theArtist.artist._id);
        setLoading(false);
        if (res) {
            if (idx >= 0) {
                if (!url) {
                     userState.theArtist.artist.socialMedias.splice(idx, 1);
                }
                else
                     userState.theArtist.artist.socialMedias[idx].url = url;
            }
            else
                userState.theArtist.artist.socialMedias.push({name: name, url: url});
            setUserState({...userState});
            setEditProfile(false);
            showNotif(false, 'Updated', 'Social media' + (!url ? ' deleted' : ' saved.'));
        }
    }

    function findEmployeeLabel() {
        let res = employeesLabel.find(x => x.val === userState.theArtist.employees);
        if (res) {
            return (res.label)
        }
        return ("N/A")
    }

    function findTurnLabel() {
        let res = turnLabel.find(x => x.val === userState.theArtist.turnover);
        if (res) {
            return (res.label)
        }
        return ("N/A")
    }
    
    return (
        <div>
            <Container>
                <Row>
                    <Col className="mx-2" align="center">
                        {
                            userState &&
                            <>
                                <div className="darkCard">
                                    <div className="profileHeader">
                                        <Image
                                            className="avatarBg"
                                            src={userState.theArtist.artist && userState.theArtist.artist?.picture ? userState.theArtist.artist.picture : logoPlain}
                                        />
                                        {
                                            <Image
                                                className="avatar"
                                                src={userState.theArtist.artist && userState.theArtist.artist.picture ? userState.theArtist.artist.picture : logoPlain}
                                                rounded
                                            />
                                        }
                                         <Button
                                            className="editBtn mt-4 py-2 pr-3 px-3 shadow-none"
                                            onClick={() => {
                                                setUsername(userState.theArtist.username)
                                                setDescription(userState.theArtist.artist.description)
                                                setImage(userState.theArtist.artist.picture)
                                                setFile(null);
                                                setPass(null);
                                                setConfPass(null);
                                                setEditProfile(true);
                                                setCroppedImage(null);
                                            }}>
                                            Edit profile
                                        </Button>
                                    </div>
                                    <h3 className="text-light text-center">{userState.theArtist.artist && userState.theArtist.username}</h3>
                                    <p className="text-light text-center mb-0">{userState.theArtist.email}</p>
                                    <p className="text-light text-center mb-0">{userState.theArtist.phoneNumber}</p>
                                    {
                                        userState.theArtist.instagram &&
                                        <a href={userState.theArtist.instagram} target="_blank" rel="noreferrer">
                                            <Image
                                                className="socialIcon"
                                                src={instagramIcon}
                                            />
                                        </a>
                                    }
                                    <Row className="mx-4 mt-2">
                                        <p className="mx-auto text-light text-justify font-weight-light">{userState.theArtist.artist && userState.theArtist.artist.description}</p>
                                    </Row>
                                </div>
                                <Image
                                    onClick={() => {props.history.goBack()}}
                                    className="closeBtnLeft mt-4 btnHov"
                                    src={close}
                                />
                                <Row className="mx-0">
                                    <Col className="darkCard mx-2">
                                        <Row>
                                            <p className="text-light text-center title mx-auto">Social medias</p>
                                            <Image className="editIcon mr-2 mt-1" src={edit} onClick={() => {
                                                setFacebook(userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Facebook')
                                                ? userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Facebook').url : null);
                                                setInstagram(userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Instagram')
                                                ? userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Instagram').url : null);
                                                setSnapchat(userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Snapchat')
                                                ? userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Snapchat').url : null);
                                                setTiktok(userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Tiktok')
                                                ? userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Tiktok').url : null);
                                                setTwitter(userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Twitter')
                                                ? userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Twitter').url : null);
                                                setYoutube(userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Youtube')
                                                ? userState.theArtist.artist.socialMedias.find(elem => elem.name === 'Youtube').url : null);
                                                setEditSocial(true);
                                            }}/>
                                        </Row>
                                        <Row className="mx-0 mb-2 justify-content-center">
                                            {
                                                userState.theArtist.artist && userState.theArtist.artist.socialMedias.length > 0 ?
                                                userState.theArtist.artist.socialMedias.map((socialMed) => {
                                                    // eslint-disable-next-line default-case
                                                    switch(socialMed.name) {
                                                        case 'Facebook':
                                                            return (
                                                                <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                    <Image
                                                                        className="socialIcon"
                                                                        src={facebookIcon}
                                                                    />
                                                                </a>
                                                            )
                                                        case 'Instagram':
                                                            return (
                                                                <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                    <Image
                                                                        className="socialIcon"
                                                                        src={instagramIcon}
                                                                    />
                                                                </a>
                                                              )
                                                        case 'Twitter':
                                                            return (
                                                                <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                    <Image
                                                                        className="socialIcon"
                                                                        src={twitterIcon}
                                                                    />
                                                                </a>
                                                              )
                                                        case 'Snapchat':
                                                            return (
                                                                <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                    <Image
                                                                        className="socialIcon"
                                                                        src={snapchatIcon}
                                                                    />
                                                                </a>
                                                              )
                                                        case 'Tiktok':
                                                            return (
                                                                <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                    <Image
                                                                        className="socialIcon"
                                                                        src={tiktokIcon}
                                                                    />
                                                                </a>
                                                              )
                                                        case 'Youtube':
                                                            return (
                                                                <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                    <Image
                                                                        className="socialIcon"
                                                                        src={youtubeIcon}
                                                                    />
                                                                </a>
                                                              )
                                                        case 'Merch':
                                                            return (
                                                                <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                    <Image
                                                                        className="socialIcon"
                                                                        src={www}
                                                                    />
                                                                </a>
                                                              )
                                                      }
                                                }) :
                                                <p className="text-light" style={{fontWeight: '300', fontSize: 14}}>Social media</p>
                                            }
                                        </Row>
                                    </Col>
                                    <Col className="darkCard mx-2">
                                        <p className="text-light text-center title">Past Experiences</p>
                                        <h2 className="text-light">{calculPastEvt()}</h2>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Button
                            className="button mt-2 py-2 pr-3 px-3 shadow-none mx-auto"
                            onClick={() => {
                                setQcm(true);
                            }}>
                            <p className="text-light my-auto upperTxt font-weight-bold">QCM</p>
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Modal centered show={editProfile && !loading} onHide={() => setEditProfile(false)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Edit your profile
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-4">
                        <Form.File
                            ref={inputOpenFileRef}
                            className="mt-2"
                            style={{ display: 'none' }}
                            accept="image/*"
                            type="file"
                            onChange={(evt) => {handleImgChange(evt, setFile, null, setCropPic)}}
                        />
                            {
                                (image || croppedImage) ? 
                                <Image
                                    className="editPic mx-auto"
                                    src={croppedImage ? URL.createObjectURL(croppedImage) : image}
                                    onClick={() => {inputOpenFileRef?.current.click()}}
                                    rounded
                                />
                                :
                                <div className="iconBg mx-auto" onClick={() => {inputOpenFileRef?.current.click()}}>
                                    <Image
                                        className="socialIcon"
                                        src={user}
                                    />
                                </div>
                            }
                        </Row>
                        <Form.Control value={username} className="shadow-none text-light" placeholder="Username" onChange={(e) => { setUsername(e.currentTarget.value); }} />
                        <Form.Control value={description}  as="textarea" rows={3} className="shadow-none text-light" placeholder="Description" onChange={(e) => { setDescription(e.currentTarget.value); }} />
                        <Form.Control value={pass} type="password" className="shadow-none text-light" placeholder="Password" onChange={(e) => { setPass(e.currentTarget.value); }} />
                        {
                            pass && confPass && pass !== confPass &&
                            <p className="passwordInfo ml-2">Passwords do not match</p>
                        }
                        <Form.Control value={confPass} type="password" className="shadow-none text-light" placeholder="Password confirmation" onChange={(e) => { setConfPass(e.currentTarget.value); }} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button" onClick={() => {updateInfo();}}>Save</Button>
                </Modal.Footer>
            </Modal>
            {CropImg(cropPic, setCroppedImage, setCropPic, file, setCrop, crop, setImageRef, imageRef, uploadPic)}
            <Modal centered show={editSocial} onHide={() => {setEditSocial(null);}}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add your social media
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="d-flex justify-content-center">
                        <Image className="socialIconForm my-auto" src={facebookIcon}/>
                        <Form.Control value={facebook} className=" my-auto shadow-none text-light socialInput ml-2" placeholder="Facebook link" onChange={(e) => { setFacebook(e.currentTarget.value); }} />
                        <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Facebook', facebook);}}>Save</Button>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Image className="socialIconForm my-auto" src={instagramIcon}/>
                        <Form.Control value={instagram} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Instagram link" onChange={(e) => { setInstagram(e.currentTarget.value); }} />
                        <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Instagram', instagram);}}>Save</Button>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Image className="socialIconForm my-auto" src={twitterIcon}/>
                        <Form.Control value={twitter} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Twitter link" onChange={(e) => { setTwitter(e.currentTarget.value); }} />
                        <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Twitter', twitter);}}>Save</Button>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Image className="socialIconForm my-auto" src={snapchatIcon}/>
                        <Form.Control value={snapchat} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Snapchat link" onChange={(e) => { setSnapchat(e.currentTarget.value); }} />
                        <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Snapchat', snapchat);}}>Save</Button>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Image className="socialIconForm my-auto" src={tiktokIcon}/>
                        <Form.Control value={tiktok} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Tiktok link" onChange={(e) => { setTiktok(e.currentTarget.value); }} />
                        <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Tiktok', tiktok);}}>Save</Button>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Image className="socialIconForm my-auto" src={youtubeIcon}/>
                        <Form.Control value={youtube} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Youtube link" onChange={(e) => { setYoutube(e.currentTarget.value); }} />
                        <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Youtube', youtube);}}>Save</Button>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Image className="socialIconForm my-auto" src={www}/>
                        <Form.Control value={merch} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Merch link" onChange={(e) => { setMerch(e.currentTarget.value); }} />
                        <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Merch', merch);}}>Save</Button>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal centered show={loading} onHide={() => setLoading(false)} className="modalLoading">
                <LoopCircleLoading color="#FA923A" size={'large'} />  
            </Modal>
            <Modal centered show={qcm} onHide={() => {setQcm(false)}} size="xl" fullscreen={true}>
                <Modal.Header closeButton>
                    <h3 className="w-100 text-center goldT">User's qcm answers</h3>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <h5 className="pt-2">Country</h5>
                            <p>{userState.theArtist.country ? userState.theArtist.country : "N/A"}</p>
                            <h5 className="pt-2">Turnover</h5>
                            <p>{findTurnLabel()}</p>
                            <h5 className="pt-2">Language</h5>
                            <p>{userState.theArtist.language ? userState.theArtist.language : "N/A"}</p>
                        </Col>
                        <Col md={4}>
                            <h5 className="pt-2">Employees</h5>
                            <p>{findEmployeeLabel()}</p>
                            <h5 className="pt-2">Business Area</h5>
                            <p>{(userState.theArtist.business && userState.theArtist.business.length > 0) ? String(userState.theArtist.business) : "N/A"}</p>
                            <h5 className="pt-2">Industry</h5>
                            <p>{userState.theArtist.industry ? userState.theArtist.industry : "N/A"}</p>
                        </Col>
                        <Col md={4}>
                             {/* <h5 className="pt-2">Minimum credits</h5>
                            <p>{userState.theArtist.minCredit ? userState.theArtist.minCredit : "N/A"}</p>
                            <h5 className="pt-2">Maximum credits</h5>
                            <p>{userState.theArtist.maxCredit ? userState.theArtist.maxCredit : "N/A"}</p> */}
                            <h5 className="pt-2">Motivation</h5>
                            <p>{userState.theArtist.motivation ? userState.theArtist.motivation : "N/A"}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button shadow-none upperTxt text-light font-weight-bold"  onClick={() => {
                        setQcm(false);
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UsersProfile;
