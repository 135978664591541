import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Help = (props) => {
    
    return (
        <Container fluid>
            <Row className="w-75 mx-auto pb-4">
                <Col>
                    <h1 className='text-light text-center mx-auto my-4 upperTxt'>Get started on AKIUS</h1>
                    <p className="text-light ml-2 pt-4">
                        Welcome to the AKIUS experience! The dashboard is the place for you to boost your career in 3 simple steps.<br />
                         -  Set up your first livestream show <br />
                         -  Invite your fans <br />
                         -  Turn your fans into credits that you can spend on our list of services, designed to help you get to the top! <br /><br />
                    </p>
                    <h4 className="text-light font-weight-bold">Here’s how to get started on AKIUS (or watch our video guide below):</h4><br />
                    <Accordion defaultActiveKey="0" className="w-100 mx-auto">
                        <Card className="faqRowTop">
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="faqHeader eventTitle">
                                Create an experience 
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                What we call ‘EXPERIENCE’ is an interactive livestream on the AKIUS app that lets you share exclusive content with your fans. It can be a live show, a pre-recorded video, or any other type of content you’d like to share. <a target="_blank" href='https://www.youtube.com/watch?v=E0AtSHFWbeo&ab_channel=AKIUS' >You can watch an example of an experience here.</a><br/><br/>
                                To create your first experience, click on “Create experience” and fill out the requested fields:<br/>
                                -  Name, date and duration of your show <br/>
                                -  Optional: set up a duration for the Meet & Greet session. The Meet & Greet allows you to pick your fans on a map and chat directly with them in a virtual room before the show starts.<br/>
                                -  Optional: sponsor. Fill in this field if a brand, label, or other type of partner is supporting your event and should appear in the live.<br/>
                                -  Tickets: “Free” means people can get free tickets right now. If you want to wait before opening the box office, select “Notify me”.<br/>
                                -  Select the privacy of your event: “public” means everybody can access it, “private” means only a list of selected people will be able to access it.<br/>
                                For more details about what’s included in the AKIUS experience, <a href='https://www.youtube.com/watch?v=QlspT6NWtII&ab_channel=AKIUS' target="_blank">watch this video. </a><br/><br/>
                                <span className="text-light font-weight-bold">Once submitted, your experience will be validated by a member of our team and we’ll quickly get back to you.</span><br /><br />
                                In the meantime, go back to your event and click on the pen icon to upload the remaining elements: a banner, a thumbnail, and the music or videos that should be played during the meet & greet and before the live starts.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="faqRow">
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="faqHeader eventTitle">
                                Invite your fans and get rewarded 
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                Every experience completed will bring you credits, and the more people attend, the more credits you get.<br/> <br/>
                                10 to 50 fans {'>'} 20 credits<br/>
                                50 to 100 fans {'>'} 30 credits<br/>
                                100 to 150 fans {'>'} 50 credits<br/>
                                150 to 200 fans {'>'} 80 credits<br/>
                                250 to 300 fans {'>'} 120 credits<br/>
                                Over 300 fans {'>'} 200 credits<br/><br/>
                                <span className="font-weight-bold">The credits will appear on your account after you completed the show.</span>Your credit balance is always visible on the top right of the dashboard. 
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="faqRow">
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="faqHeader eventTitle">
                            Pick your services and enjoy!
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                 In the ‘SHOP’ tab, you will find the list of all the services that AKIUS is currently offering. The list includes <a href='https://www.youtube.com/watch?v=xXFnBJvdEW8&t=83s&ab_channel=AKIUS' target="_blank">special shows in famous venues</a>, coaching sessions with music professionals, tailor-made events according to your needs...<br/>
                                 The cost associated with each service is displayed on its thumbnail.<br/><br/>
                                <span className="font-weight-bold">Apply for the services you need and the team will get back to you shortly. </span><br/><br/>
                                    You can pay for the services with the credits you earned by doing live experiences.<br/><br/>
                                <span className="text-purple">Good to know: you can also buy credits in the “PLANS” tab. Keep in mind that you can always combine the credits you earn and the credits you buy to apply for a specific service.</span>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="faqRowBot">
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="faqHeader eventTitle">
                                You’re a label: manage several artists
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                The ‘ARTISTS’ tab is only visible if you’re logged in as a label. It will let you manage your artist portfolio and follow the activity and statistics for each of them. <br/><br/>
                                To create a new artist, click on ‘Create an artist’ and fill out the appropriate fields.<br/><br/>
                                <span className="font-weight-bold">You’re good to go! </span> <br/><br/>
                                If you have any issue while using the dashboard, reach us through the chat at the bottom right of your screen. <br/><br/>
                                <span className="font-weight-bold">
                                Enjoy your experience on AKIUS!
                                </span>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="faqRowBot">
                            <Accordion.Toggle as={Card.Header} eventKey="1" className="faqHeader eventTitle">
                                Can't find your answer?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>You can chat with us by clicking on the bottom right message icon</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <Row className="my-4 mx-0 justify-content-center">
                        <iframe width="660" height="415" src="https://www.youtube.com/embed/bPnhgwTIdds?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Help;
