import React, {useContext, useEffect, createRef, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { UserContext } from "../Context/UserContext";
import { getArtists, deleteUser} from "../Services/user.service";
import logoPlain from '../Assets/logo.png';
import add from '../Assets/add.png';
import { LoopCircleLoading } from 'react-loadingg';
import {showNotif, handleImgChange} from '../Component/Utils';
import {registerUser} from "../Services/auth.service";
import CropImg from '../Component/CropImg';
import plus from '../Assets/plus.png';
import trash from '../Assets/trash.png';

const Users = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [tmpArtist, setTmpArtist] = useState('');
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [instagram, setInstagram] = useState('');
    const [loading, setLoading] = useState(false)
    const [deleteArtist, setDeleteArtist] = useState(false)
    const [RegisterModal, setRegisterModal] = useState(false);
    const [file, setFile] = useState(null);
    const inputOpenFileRef = createRef();
    const [crop, setCrop] = useState({aspect: 1, width: 300});
    const [cropPic, setCropPic] = useState(null);
    const [croppedImage, setCroppedImage] = useState('');
    const [imageRef, setImageRef] = useState('');
    const [confirmation, setConfirmation] = useState('');
    const [phone, setPhone] = useState('');
    const [theArtist, setTheArtist] = useState(null);

    useEffect(() => {
        if (!userState || !userState.token)
            props.history.push('/login');

        if (userState && userState.token) {
            getArtists(userState.token)
            .then(res => {
                if (res) {
                    setUserState({...userState, artists: res.users});
                    setTmpArtist(res.users);
                }
            });
        }
    }, [props.history]);

    if (!userState.artists) {
        return <div />
    }

    async function registerUserReq() {
        // var regex = /[A-Z]/;
        // else if (password.length < 8 || !regex.test(password)) {
        //     showNotif(true, 'Error password', 'Password must be at least 8 characters and it must contains at least 1 capital letter.');
        // }
        // if (!password || !username || !croppedImage || !email || !phone || !instagram)
        if (!password || !croppedImage || !email || !phone)
            showNotif(true, 'Error', "Please enter the artist's username, email, phone number password and profile picture.");
        else {
            setLoading(true);
            console.log("ALLO")
            try {
                let res = await registerUser(username, username, password, email, description, croppedImage, phone);
                console.log("A")
                setRegisterModal(false);
                getArtists(userState.token);
                if (res) {
                    setUserState({...userState, artists: res.users});
                    showNotif(false, 'Succesful', 'User created');
            }
            } catch (e) {
                console.error(e);
                showNotif(true, 'Error server', 'Error on sending email, but user has been created');
            } finally {
                setRegisterModal(false);
                setLoading(false);
            }       
        }
    }

    return (
        <div id="oiyjhor">
            <Container fluid>
                <Row className="mx-0">
                    <Form inline className="ml-4 mt-0">
                        <FormControl type="text" placeholder="Search" className="inputSearch shadow-none m-0 ml-4"
                        onChange={(e) => {
                            if (e.currentTarget.value && e.currentTarget.value.length > 0 && userState.artists) {
                                let tmp = tmpArtist;
                                setUserState({...userState, artists: tmp.filter(el => el.username.toLowerCase().includes(e.currentTarget.value.toLowerCase()) ||
                                    el.email.toLowerCase().includes(e.currentTarget.value.toLowerCase()) ||
                                    el.artist.name.toLowerCase().includes(e.currentTarget.value.toLowerCase()))});
                            }
                            else {
                                setUserState({...userState, artists: tmpArtist});
                            }
                        }} />
                    </Form>
                    <Button
                        className="addArtist ml-auto mr-4 shadow-none mt-2"
                        onClick={() => {
                            setUsername('');
                            setPassword('');
                            setName('');
                            setDescription('');
                            setInstagram('');
                            setEmail('');
                            setPhone('');
                            setConfirmation('');
                            setImageRef('');
                            setFile(null);
                            setRegisterModal(true);
                        }}>
                        <Image className="addIcon mr-2 mb-1" src={add} />
                        Create user
                    </Button>
                </Row>
                <Row className="mx-3 justify-content-center mt-4">
                {userState && userState.artists && userState.artists.map((elem) => {
                        return (
                            <Col className="d-flex artistCard flex-column mb-4 mt-4" md={3} align="center">
                                <Image
                                    onClick={() => {setUserState({...userState, theArtist: elem}); props.history.push('/userProfile');}}
                                    className="cardImg mx-auto"
                                    src={elem.artist.picture ? elem.artist.picture : logoPlain}
                                    roundedCircle
                                />
                                <p className="text-light text-center mx-auto upperTxt mt-2">{elem.username}</p>
                                <Image
                                    onClick={() => {setTheArtist(elem); setDeleteArtist(true)}}
                                    className="deleteArtist mx-auto btnHov"
                                    src={trash}
                                />
                            </Col>
                        )
                    })
                }
                </Row>
            </Container>
            <Modal centered show={RegisterModal && !loading && !cropPic} onHide={() => setRegisterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    User information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-2">
                        <Form.File
                            ref={inputOpenFileRef}
                            className="mt-2"
                            style={{ display: 'none' }}
                            accept="image/*"
                            type="file"
                            onChange={(evt) => {handleImgChange(evt, setFile, null, setCropPic)}}
                        />
                            {
                                croppedImage ? 
                                <Image
                                    className="editPic mx-auto"
                                    src={URL.createObjectURL(croppedImage) }
                                    onClick={() => {inputOpenFileRef?.current.click()}}
                                    rounded
                                />
                                :
                                <div className="iconBg mx-auto" onClick={() => {inputOpenFileRef?.current.click()}}>
                                    <Image
                                        className="socialIcon"
                                        src={plus}
                                    />
                                </div>
                            }
                        </Row>
                        <p className="text-light text-center">Profile picture*</p>
                        <Form.Control value={username} className="shadow-none text-light" placeholder="Name*" onChange={(e) => { setUsername(e.currentTarget.value); setName(e.currentTarget.value); }} />
                        {/* <Form.Control value={name} className="shadow-none text-light" placeholder="Name" onChange={(e) => { setName(e.currentTarget.value); }} /> */}
                        <Form.Control value={email}  autoComplete="new-password" className="shadow-none text-light" placeholder="Email*" onChange={(e) => { setEmail(e.currentTarget.value); }} />
                        <Form.Control value={password} autoComplete="new-password" className="shadow-none text-light" type="password" placeholder="Password*" onChange={(e) => { setPassword(e.currentTarget.value); }} />
                        <Form.Control value={confirmation} autoComplete="new-password" className="shadow-none text-light" type="password" placeholder="Password confirmation*" onChange={(e) => { setConfirmation(e.currentTarget.value); }} />
                        {
                            password && confirmation && password !== confirmation &&
                            <p className="passwordInfo ml-2">Passwords do not match</p>
                        }
                        <Form.Control value={phone} className="shadow-none text-light" placeholder="Phone number*" onChange={(e) => { setPhone(e.currentTarget.value); }} />
                        {/* <Form.Control value={instagram} className="shadow-none text-light" placeholder="Instagram link*" onChange={(e) => { setInstagram(e.currentTarget.value); }} /> */}
                        {/* <Form.Control value={description} className="shadow-none text-light" placeholder="Description" onChange={(e) => { setDescription(e.currentTarget.value); }} /> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button shadow-none" disabled={password !== confirmation} onClick={() => {registerUserReq();}}>Create</Button>
                </Modal.Footer>
            </Modal>
            {CropImg(cropPic, setCroppedImage, setCropPic, file, setCrop, crop, setImageRef, imageRef)}
            <Modal centered show={loading} onHide={() => setLoading(false)} className="modalLoading">
                <LoopCircleLoading color="#FA923A" size={'large'} />  
            </Modal>
            <Modal centered show={deleteArtist} onHide={() => {setDeleteArtist(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Are you sure to delete this artist ?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <p className="text-center">{theArtist?.username}</p>
                </Modal.Body>
                <Modal.Footer className="pt-0">
                    <Button className="button btnHov" onClick={() => {
                        deleteUser(theArtist._id, userState.token);
                        setDeleteArtist(false);
                        getArtists(userState.token)
                        .then(res => {
                            if (res) {
                                setUserState({...userState, artists: res.users});
                            }
                        });
                    }}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Users;