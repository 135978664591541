import {showNotif} from '../Component/Utils';
import conf from './confs';

export const getEvents = async (token) => {
    const response = await fetch(conf.api.url + 'events_artist', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
      });
    if (response.status >= 400) {
        // showNotif(true, 'Error', 'Error of request');
        return (false);
    }
    else {
        const json = await response.json();
        return json;
    }
};

export const getEventsToValidate = async (token) => {
    const response = await fetch(conf.api.url + 'events_to_validate', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
      });
    if (response.status >= 400) {
        // showNotif(true, 'Error', 'Error of request');
        return (false);
    }
    else {
        const json = await response.json();
        return json;
    }
};

export const getEventSongs = async (token, id) => {
    const response = await fetch(conf.api.url + 'events/' + id + '/playlist', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
      });
    if (response.status >= 400) {
        return (false);
    }
    else {
        const json = await response.json();
        return json;
    }
};

export const getEvent = async (token, id) => {
    const response = await fetch(conf.api.url + 'events/' + id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
      });
    if (response.status >= 400) {
        return (false);
    }
    else {
        const json = await response.json();
        console.log(json);
        return json;
    }
};

export const getSuperEvents = async (token, id) => {
    const response = await fetch(conf.api.url + 'events_artist?artist=' + id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`,
        },
      });
    if (response.status >= 400) {
        return (false);
    }
    else {
        const json = await response.json();
        return json;
    }
};

export const uploadEventPic = async (file, id, token) => {
  const data = new FormData();
  data.append('file', file);
  const response = await fetch(conf.api.url + 'events/' + id +  '/picture', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: data
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Image not saved')
      return (false);
  }
  else {
      const url = await response.json();
      return url;
  }
};

export const uploadeVideo = async (file, id, token, aktime) => {
  const data = new FormData();
  data.append('video', file);
  data.append('name', aktime ? 'aktime' : 'sas');
  const response = await fetch(conf.api.url + 'events/' + id + (aktime ? '/aktime_videos' : '/videos'), {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: data
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Video not saved')
      return (false);
  }
  else {
      const url = await response.json();
      return url;
  }
};

export const uploadSong = async (file, id, token) => {
  console.log(file);
  const data = new FormData();
  data.append('song', file);
  data.append('name', file.name.slice(0, -4));
  const response = await fetch(conf.api.url + 'events/' + id + '/playlist', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: data
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Song not added.')
      return (false);
  }
  else {
      const url = await response.json();
      return url;
  }
};

export const uploadeVignette = async (file, id, token, firstVign) => {
  const data = new FormData();
  data.append(firstVign === true ? 'file1' : 'file2', file);
  const response = await fetch(conf.api.url + 'events/' + id + '/placeholders', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: data
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Placeholder not saved')
      return (false);
  }
  else {
      const url = await response.json();
      return url;
  }
};

export const updateEvent = async (body, id, token) => {
  const response = await fetch(conf.api.url + 'events/' + id, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', "Event information not saved.")
      return (false);
  }
  else {
      return true;
  }
};

export const createEvent = async (body, token) => {
  const response = await fetch(conf.api.url + 'events/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  if (response.status >= 400) {
    console.log(response)
      let res = await response.json();
      console.log(res);
      showNotif(true, 'Error', res.message)
      return (false);
  }
  else {
      return response.json();
  }
};

export const deleteEvent = async (id, token) => {
  const response = await fetch(conf.api.url + 'events/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', "Event has not been deleted.")
      return (false);
  }
  else {
      return true;
  }
};

export const deleteSong = async (id, token, songId) => {
  const response = await fetch(conf.api.url + 'events/' + id + '/playlist/' + songId, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', "Song has not been deleted.")
      return (false);
  }
  else {
      return true;
  }
};

export const joinRoom = async (evtId, token) => {
  const response = await fetch(conf.api.url + 'events/rooms/join?id=' + evtId, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.status >= 400) {
      showNotif(true, 'Error', "Join room failed.")
      return (false);
  }
  else {
      return true;
  }
}

export const getEventRoom = async (evtId, token) => {
  const response = await fetch(conf.api.url + 'events/rooms/' + evtId, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    });
    console.log(response);
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Get rooms failed.');
      return (false);
  }
  else {
      const json = await response.json();
      return json;
  }
};


export const createLive = async (evtId, token) => {
  const response = await fetch(conf.api.url + 'livestreams/' + evtId, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
  });
  if (response.status >= 400) {
      showNotif(true, 'Error', 'createLive failed.');
      return (false);
  }
  else {
      const json = await response.json();
      return json;
  }
}

export const stopLive = async (evtId, token) => {
  const response = await fetch(conf.api.url + 'livestreams/' + evtId + '/stop', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
  });
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Stop live failed.');
      return (false);
  }
  else {
      const json = await response.json();
      return json;
  }
}

export const streamAction = async (evtId, token, action) => {
  console.log("ACTION REQ")
  const response = await fetch(conf.api.url + 'livestreams/' + evtId, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({
      state: action
    }),
  });
  console.log("res ", response)
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Setting stream action to ' + action + ' failed');
      return (false);
  }
  else {
      const json = await response.json();
      return json;
  }
}

export const downloadLive = async (evtId, token) => {
  console.log("ACTION REQ")
  const response = await fetch(conf.api.url + `events/get_video?user=${evtId}&event=${evtId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`,
    }
  });
  console.log("res ", response)
  if (response.status >= 400) {
      return (false);
  }
  else {
      const json = await response.json();
      return json;
  }
}