import {showNotif} from '../Component/Utils';
import conf from './confs';


export const login = async (email, password) => {

  try {
    const response = await fetch(conf.api.url + 'loginArtist', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    if (response.status >= 400) {
        showNotif(true, 'Error', 'Check your email or password')
        return (false);
    }
    else {
        const json = await response.json();
        console.log(json);
        localStorage.setItem('token', json.token)
        return json;
    }
  }
  catch (err) {
    showNotif(true, 'Error', 'Error on server, please  try later.');
    return(false);
  }
};

const addArtistInfo = async (name, picture, description, token) => {
  console.log("addArtistInfo");

  const data = new FormData();
  data.append('file', picture);
  data.append('description', description);
  data.append('name', name);
  const response = await fetch(conf.api.url + 'users/artist', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: data
    });
    console.log("fetch artist", response);
  if (response.status >= 400) {
      showNotif(true, 'Error on save', 'Image not saved.')
      return (false);
  }
  else {
      console.log("return true")
      return true;
  }
};

export const forgotPass = async (email) => {
  const response = await fetch(conf.api.url + 'forgotten_password', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });
  if (response.status >= 400) {
      showNotif(true, 'Error on send', 'Email not found.')
      return (false);
  }
  else {
      showNotif(false, 'Email sent', 'We have sent you an email with a new code.')
      return true;
  }
};

export const registerUser = async (username, name, password, email, description, picture, phoneNumber) => {
  try {
    const response = await fetch(conf.api.url + 'signup', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        username,
        phoneNumber,
        isLabel: false,
        isCreator: false,
        referal: undefined,
      }),
    });
    console.log("helloo", response);
    if (response.status >= 400) {
        showNotif(true, 'Error', response.status == 409 ? 'Email already exist.' : 'register failed. Try later.')
        return (false);
    }
    else {
      const json = await response.json();

      localStorage.setItem('token', json.token)
      if (name || picture || description)
        return addArtistInfo(name, picture, description, json.token);
      else
        return json;
    }
  }
  catch (err) {
    showNotif(true, 'Error', 'Error on server, please  try later.');
    return(false);
  }
};



