import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from "../Context/UserContext";
import logoPlain from '../Assets/logo.png';
import moment from 'moment';
import conf from '../Services/confs';
import { LoopCircleLoading } from 'react-loadingg';
import saved from '../Assets/saved.png';
import unsave from '../Assets/unsave.png';
import { saveCamp } from '../Services/user.service'

const Academy = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [incoming, setIncoming] = useState(true);
    const [res, setRes] = useState([]);
    const [TmpRes, setTmpRes] = useState([]);
    const [favs, setFavs] = useState([]);

    useEffect(() => {
        if (userState && userState.token && userState.user.acceptedCGU == true) {
            // if (userState.user.isLabel === true)
            //     props.history.push('/artistExp'); 
            // else {
                setLoading(true);
                fetch(conf.api.urlGBF + "campaigns?locale=en&populate=*&filters[type][$eq]=event&pagination[pageSize]=1000").then((res) => res.json()).then(async (res) => {
                    if (res.data == null) {
                      return false;
                    }
                    setRes(res.data);
                    setTmpRes(res.data);
                    getFavorites();
                })
            // }
        }
        else {
            props.history.push('/login'); 
        }
    }, [props.history]);

    useEffect(() => {
        setTimeout(()=> {
        }, 3000)
    }, [window]);

    function getFavorites() {
        fetch(conf.api.urlGBF + "favorites?populate=*&filters[users][$containsi]=" + userState.user._id).then((res) => res.json()).then(async (res) => {
            if (res.data == null) {
              return false;
            }
            setFavs(res.data);
            setLoading(false);

        });
    }

    const mentorDisplay = (elem) => {
        return (
            <Col className="mb-2 d-flex cardBodyEvent px-0 flex-column mx-4 mt-4" align="center">
                <Image
                    className="eventBg"
                    style={{cursor: 'pointer'}}
                    src={elem.attributes.img ? elem.attributes.img.data[0].attributes.url : logoPlain}
                    onClick={() => {
                        props.history.push({
                            pathname: '/detail',
                            state: { data: elem, btn: 'Take part', keyword: 'mentor' }
                        });
                    }}
                />
                <div className="eventTop">
                    <h5 className="eventTitleMedium">{elem.attributes.name}</h5>
                    <p className="smTitle">{moment(new Date(elem.attributes.date)).format('MMMM D, yyyy')}</p>
                </div>
                <Row className="xpBottom mx-auto">
                    <Button
                        className="buttonEvent py-2 pr-3 px-3 shadow-none mx-auto upperTxt text-light font-weight-bold"
                        onClick={() => {
                            props.history.push({
                                pathname: '/detail',
                                state: { data: elem, btn: 'Take part', keyword: 'mentor' }
                            });
                        }}>
                        {'Details'}
                    </Button>
                    <Button
                        className="buttonEvent py-2 pr-3 px-3 shadow-none mx-auto upperTxt text-light font-weight-bold"
                        onClick={() => {
                            let fav = favs.find(el => el.attributes.campaign.data.id === elem.id);
                            saveCamp(fav, userState.user._id, setLoading, fav ? fav.id : elem.id, undefined, getFavorites)
                        }}>
                         <Image
                            className="heartIcon mx-auto btnHov"
                            src={favs.find(el => (el.attributes.campaign.data && el.attributes.campaign.data.id === elem.id)) ? saved : unsave}
                        />
                    </Button>
                </Row>
            </Col>
        )
    }

    return (
        <div>
            <Container>
                <Row className="mt-4 justify-content-center">
                    {/* <Button className={`mr-2 shadow-none upperTxt text-light font-weight-bold ${incoming ? 'buttonEvent' : 'buttonDisableBlue'}`} onClick={() => {setIncoming(true)}}>UPCOMING</Button>
                    <Button className={`mr-2 shadow-none upperTxt text-light font-weight-bold ${!incoming ? 'buttonEvent' : 'buttonDisableBlue'}`} onClick={() => {setIncoming(false)}}>PAST</Button> */}
                    <Form inline>
                        <FormControl type="text" placeholder="Search" className="inputSearch shadow-none m-0 ml-4"
                        onChange={(e) => {
                            if (e.currentTarget.value && e.currentTarget.value.length > 0) {
                                let idx = -1;
                                let arr = [];
                                for (let i = 0; i < TmpRes.length; i++) {
                                    if (TmpRes[i].attributes.hashtags) {
                                        idx = TmpRes[i].attributes.hashtags.findIndex(el => {
                                            return el.toLowerCase().includes(e.currentTarget.value.toLowerCase())
                                        });
                                    }
                                    if (idx >= 0) {
                                        arr.push(TmpRes[i]);
                                        idx = -1;
                                    }
                                }
                                setRes(arr);
                            }
                            else {
                                if (res.length !== TmpRes)
                                    setRes(TmpRes);
                            }
                        }} />
                    </Form>
                </Row>
                <Row  className="pt-4 mx-auto">
                    {res.map((elem) => {
                        return mentorDisplay(elem);
                        // let mentorDate = new Date(elem.attributes.date);
                        // let today = new Date();
                        // if (incoming && mentorDate >= today){
                        //     return (mentorDisplay(elem));
                        // }
                        // else if (!incoming && (mentorDate < today))
                        //     return mentorDisplay(elem);
                        // return (<></>)
                    })}
                </Row>
                <Modal centered show={loading} onHide={() => setLoading(false)} className="modalLoading">
                    <LoopCircleLoading color="#FA923A" size={'large'} />  
                </Modal>
            </Container>
        </div>
    );

}

export default Academy;