import {useState, useContext, useEffect, createRef} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { updateQcmInfo, updateAcceptInfo } from "../Services/user.service";
import {showNotif, languages, listIndustry, countries, listContinent} from '../Component/Utils';
import {login, registerUser, forgotPass} from "../Services/auth.service";
import { UserContext } from "../Context/UserContext";
import { LoopCircleLoading } from 'react-loadingg';
import conf from '../Services/confs';
import logoPlain from '../Assets/logo.png';
import { ContinuousColorLegend } from 'react-vis';

const Login = (props) => {
    // const [password, setPassword] = useState('ZitaAkius2020');
    // const [email, setEmail] = useState('zita@akius.com');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [forgotEmail, setForgotEmail] = useState('');
    const [renew, setRenew] = useState(false);
    const [show, setShow] = useState(false);
    const [thanks, setThanks] = useState(false);
    const [motivation, setMotivation] = useState();
    const [keys, setKeys] = useState("");
    const [userState, setUserState] = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [credit, setCredit] = useState(false);
    const [country, setCountry] = useState();
    const [industry, setIndustry] = useState();
    const [lang, setLang] = useState();
    const [employees, setEmployees] = useState({val: -1, label: ""});
    const [business, setContinent] = useState([]);
    const [turn, setTurn] = useState({val: "", label: ""});
    const [listKeys, setListKeys] = useState([]);
    const [mentors, setMentors] = useState([]);
    const [sugg, setSugg] = useState(false);
    const [min, setMin] = useState();
    const [max, setMax] = useState();
    const [qcm, setQcm] = useState(false);
    const [titlePro, setTitlePro] = useState('');
    const [name, setName] = useState('');
    const [society, setSociety] = useState('');
    const [emailForm, setEmailForm] = useState('');
    const [accept, setAccept] = useState(false);
    const [terms, setTerms] = useState(false);
    const [tmpUser, setTmpUser] = useState(null);

    useEffect(() => {
        if (userState && userState.token && userState.user.acceptedCGU == true) {
            props.history.push('/');
        }
    }, []);

    function getFlag(label) {
        var result = languages.find(obj => {
            return obj.label === label
          });
        if (result)
            return (result.flag);
    }

    async function authUser(newMember) {
        if (!email || !password) {
            showNotif(true, 'Error on connection', 'Missing password or email.');
            if (loading)
                setLoading(false);
        }
        else {
            if (!loading)
                setLoading(true);
            let res = await login(email, password);
            setLoading(false);
            if (res) {
                console.log("get user login iunfo ",  res.user);
                setTmpUser(res);
                setUserState({...userState, user: res.user, token: res.token, newMember: newMember});
                setLoading(false);
                if (res.user.isLabel || (res.user.isFirstLogin == false && res.user.acceptedCGU == true))
                    props.history.push('/');
                else {
                    if (res.user.acceptedCGU == false)
                        setTerms(true);
                    else if (res.user.isFirstLogin == true)
                        setQcm(true);
                }
                // props.history.push('/');
               
                // if (!newMember && window.fcWidget) {
                //     window.fcWidget.open();
                // }
            }
        }
    }

    function getMentors() {
        //chekc min credit max credit lang lang2 lang3 and industry
        fetch(conf.api.urlGBF + "campaigns?locale=en&populate=*&filters[type][$eq]=mentor").then((res) => res.json()).then(async (res) => {
            let arr =[];
            if (res.data == null) {
              return false;
            }
            for (let i = 0; i < res.data.length; i++) {
                if ((!industry || (industry && res.data[i].attributes.industries && res.data[i].attributes.industries == industry)) &&
                    (!max || (res.data[i].attributes.credit <= max)) && (!min || (res.data[i].attributes.credit >= min)) &&
                    (!lang || (res.data[i].attributes.lang == lang || res.data[i].attributes.lang2 == lang || res.data[i].attributes.lang3 == lang))) {
                    arr.push(res.data[i])
                }
            }
            setMentors(arr);
            if (arr.length > 0)
                setSugg(true);
            else
                props.history.push('/');
        })
    }

    async function saveQcm() {
        setLoading(true);
        let res = await updateQcmInfo(country, turn.val, lang, employees.val, business, industry, min, max, motivation, false, userState.user._id, userState.token);
        if (res) {
            console.log("res ", res);
            userState.user.country = country;
            userState.user.turnover = turn.val;
            userState.user.language = lang;
            userState.user.employees = employees.val;
            userState.user.business = business;
            userState.user.industry = industry;
            userState.user.minCredit = min;
            userState.user.maxCredit = max;
            userState.user.motivation = motivation;
            setUserState({...userState});
        }
        setLoading(false);
    }

    async function saveAccept() {
        setLoading(true);
        let res = await updateAcceptInfo(true, userState.user._id, userState.token);
        if (res) {
            console.log("res ", res);
            userState.user.acceptedCGU = true;
            setUserState({...userState});
        }
        setLoading(false);
        if (tmpUser.user.isFirstLogin == true)
            setQcm(true);
        else
            props.history.push('/');
    }

    function handleKeyPress(target) {
        if(target.charCode == 13){
          if (keys) {
            if (listKeys.indexOf(keys) < 0)
                setListKeys(listKeys.concat(keys));
            setKeys("");
          }  
        } 
      }

      function sendEmail(subject, content) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
    
        var raw = JSON.stringify({
            "to": "jdrombi1@gmail.com",
            "text": content,
            "subject": subject
        });
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch("https://strapi.getbigfast.club/api/email", requestOptions)
        .then(response => {response.text(); console.log("response ", response)})
        .then(result => {console.log("result ", result)})
        .catch(error => {console.log('error', error);});
    }

    return (
        <Col className="loginBox mx-0 d-flex justify-content-center">
            {
                loading ? <LoopCircleLoading color="#FA923A" size={'large'} /> :
                <Container className="my-auto">
                    <Row className="pt-4 mx-auto mb-4 justify-content-center">
                        <Col className="mb-2 p-4 d-flex flex-column cardBody">
                            <Form className="mb-4" autoComplete="off">
                                <Form.Group>
                                    <Form.Control
                                        className="inputBg text-light shadow-none box"
                                        type="text"
                                        placeholder="Email"
                                        autoComplete="new-password"
                                        value={email}
                                        onChange={(e) => { setEmail(e.currentTarget.value); }}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        className="inputBg text-light shadow-none box"
                                        type="password"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(e) => { setPassword(e.currentTarget.value); }}
                                        placeholder="Password"/>
                                        <Form.Text className="ml-2 mt-2 btnHov text-light" onClick={() => {setForgotEmail('');setRenew(true)}}>
                                            Forgot your password?
                                        </Form.Text>
                                </Form.Group>
                            </Form>
                            <Button
                                className="button mt-auto py-2 pr-3 px-3 shadow-none upperTxt text-light font-weight-bold"
                                disabled={!email || !password}
                                onClick={() => {authUser(false)}}>
                                Sign In
                            </Button>
                            <Form.Text className="btnHov text-light mt-2 ml-2" onClick={() => {setShow(true)}}>
                            You don’t have an account ? Sign up now
                            </Form.Text>
                            {/* <Button
                                className="button mt-auto py-2 pr-3 px-3 shadow-none mb-4"
                                onClick={() => {setQcm(true)}}>
                                QCM
                            </Button> */}
                        </Col>
                    </Row>
                    <Modal centered show={renew} onHide={() => setRenew(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Forgot your password ? You will receive a temporary code so you can login and update your password.
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Control value={forgotEmail} className="shadow-none text-light" placeholder="Email*" onChange={(e) => { setForgotEmail(e.currentTarget.value); }} />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="button shadow-none" disabled={!forgotEmail} onClick={async () => {
                                setLoading(true);
                                await forgotPass(forgotEmail);
                                setRenew(false);
                                setLoading(false);
                            }}>Send temporary code</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal centered show={terms} onHide={() => setTerms(false)} size="lg">
                        <Modal.Body className="spaceTop">
                        {/* <label className="checkContainer">
                            By clicking on “Confirm” <br />I accept the <span
                            className="text-white pointer" onClick={() => {
                                const win = window.open('https://getbigfast.club/fr/terms', "_blank");
                                win.focus();
                            }}
                            >terms and conditions of use </span>and I have read the 
                            <span 
                                className="text-white pointer" onClick={() => {
                                const win = window.open('https://getbigfast.club/fr/privacy', "_blank");
                                win.focus();
                            }}
                            > privacy policy.</span>
                            <input type="checkbox" onClick={() => {setAccept(!accept)}} />
                            <span class="checkmark"></span>
                        </label> */}
                        <h5 className="text-center">By clicking on “Confirm”</h5>
                        <h5 className="text-center">
                            I accept <span
                            className="text-white pointer" onClick={() => {
                                const win = window.open('https://getbigfast.club/fr/terms', "_blank");
                                win.focus();
                            }}
                            >Terms of service</span>
                        </h5>
                        <h5 className="text-center">
                            and I have read  
                            <span 
                                className="text-white pointer" onClick={() => {
                                const win = window.open('https://getbigfast.club/fr/privacy', "_blank");
                                win.focus();
                            }}
                            > Privacy policy</span> and 
                            <span 
                                className="text-white pointer" onClick={() => {
                                const win = window.open('https://getbigfast.club/fr/cookie', "_blank");
                                win.focus();
                            }}
                            > Cookie policy.</span>
                        </h5>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <p className="reserved text-center m-0 pb-2">
                                <b>
                                <span className="text-white pointer" onClick={() => {
                                    const win = window.open('https://getbigfast.club/fr/privacy', "_blank");
                                    win.focus();
                                }}>Privacy policy</span> | <span className="text-white pointer" onClick={() => {
                                const win = window.open('https://getbigfast.club/fr/terms', "_blank");
                                win.focus();
                                }}>Terms of use</span>
                            </b></p> */}
                            <Button className="button zindexBtn shadow-none" onClick={async () => {
                                setTerms(false);
                                setLoading(true);
                                saveAccept();
                            }}>Confirm</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal centered show={credit} backdrop="static" onHide={() => {setCredit(false); props.history.push('/');}}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter" className="goldT">
                                You need credits
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Buy credits to meet your mentor
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="button shadow-none upperTxt text-light font-weight-bold" onClick={() => {
                                props.history.push('/credit');
                            }}>Buy</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal centered show={sugg} onHide={() => {setSugg(false); props.history.push('/')}} size="xl">
                        <Modal.Header closeButton>
                            <h3 className="w-100 text-center goldT">
                                Congrats ! Now choose the mentor you need to meet
                            </h3>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="mx-3 justify-content-center mt-4">
                           {
                                mentors.map((elem) => {
                                    return (
                                        <Col className="d-flex artistCard flex-column mb-4 mt-4" md={3} align="center">
                                        <Image
                                            className="cardImg mx-auto"
                                            src={elem.attributes.img ? elem.attributes.img.data[0].attributes.url : logoPlain}
                                            onClick={() => {setSugg(false);setCredit(true);}}
                                            roundedCircle
                                        />
                                        <p className="text-light text-center mx-auto upperTxt mt-2 mb-0">{elem.attributes.name}</p>
                                        <p className="text-light text-center mx-auto mb-0">{elem.attributes.credit} Credits</p>
                                        <p className="">{getFlag(elem.attributes.lang)}{getFlag(elem.attributes.lang2)}{getFlag(elem.attributes.lang3)}</p>
                                        {/* <Button className="buttonEvent py-2 pr-3 px-3 shadow-none mx-auto" onClick={async () => {
                                        }}>Choose</Button> */}
                                    </Col>
                                    )
                                })
                           }
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal centered show={qcm} onHide={() => {setQcm(false); props.history.push('/');}} backdrop="static" size="xl" fullscreen={true}>
                        <Modal.Header closeButton>
                            <h3 className="w-100 text-center goldT">Easily find the mentors you need among the most inspiring of our generation</h3>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={4}>
                                    <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                                        <Dropdown.Toggle className="w-75 droptext">{country ? country : "Select your country"}</Dropdown.Toggle>
                                        <Dropdown.Menu className="countryDrop">
                                            {
                                                countries.map((item, id) => {
                                                    return (
                                                        <Dropdown.Item className={country == item.name ? "dropSelect" : ""} eventKey={id} onClick={()=> setCountry(item.name)}>{item.name}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                                        <Dropdown.Toggle>{turn.val ? turn.label : "Select your revenue"}</Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdownMenu">
                                            <Dropdown.Item eventKey="1" className={turn.val == 10000 ? "dropSelect" : ""} onClick={()=> setTurn({val: 10000, label: "$10K"})}>$10K</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" className={turn.val == 100000 ? "dropSelect" : ""} onClick={()=> setTurn({val: 100000, label: "$100K"})}>$100K</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" className={turn.val == 1000000 ? "dropSelect" : ""} onClick={()=> setTurn({val: 1000000, label: "$1M"})}>$1M</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" className={turn.val == 1000001 ? "dropSelect" : ""} onClick={()=> setTurn({val: 1000001, label: "More"})}>More</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                                        <Dropdown.Toggle>{lang ? lang : "Select Language"}</Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdownMenu">
                                            {
                                                languages.map((item, id) => {
                                                    return (
                                                        <Dropdown.Item className={lang == item.label ? "dropSelect" : ""} eventKey={id} onClick={()=> setLang(item.label)}>{item.label}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </Col>
                                    <Col md={4}>
                                    <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                                        <Dropdown.Toggle>{employees.val != -1 ? employees.label : "Select employees"}</Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdownMenu">
                                            <Dropdown.Item eventKey="1" className={employees.val == 0 ? "dropSelect" : ""} onClick={()=> setEmployees({val: 0, label: "0"})}>0</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" className={employees.val == 1 ? "dropSelect" : ""} onClick={()=> setEmployees({val: 1, label: "1   to  10"})}>1   to  10</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" className={employees.val == 11 ? "dropSelect" : ""} onClick={()=> setEmployees({val: 11, label: "11  to  50"})}>11  to  50</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" className={employees.val == 51 ? "dropSelect" : ""} onClick={()=> setEmployees({val: 51, label: "More"})}>More</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                                        <Dropdown.Toggle className="droptext">{business.length > 0 ? String(business) : "Select your business area"}</Dropdown.Toggle>
                                        <Dropdown.Menu className="continentDrop">
                                            {
                                                listContinent.map((item, idx) => {
                                                    return (
                                                        <Dropdown.Item eventKey={idx} className={business.includes(item) ? "dropSelect" : ""} onClick={()=> {
                                                            let id = business.indexOf(item);
                                                            let tmp = [...business];
                                                            if (id < 0)
                                                                setContinent(business.concat(item));
                                                            else {
                                                                tmp.splice(id, 1);
                                                                setContinent(tmp);
                                                            }
                                                        }}>{item}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                                        <Dropdown.Toggle>{industry ? industry : "Select Industry"}</Dropdown.Toggle>
                                        <Dropdown.Menu className="countryDrop">
                                            {
                                                listIndustry.map((item, id) => {
                                                    return (
                                                        <Dropdown.Item className={industry == item ? "dropSelect" : ""} eventKey={id} onClick={()=> setIndustry(item)}>{item}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            <Col md={4}>
                                        {/* <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                                            <Dropdown.Toggle>{min ? (min + ' credits') : "Minimum credits"}</Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdownMenu">
                                                <Dropdown.Item eventKey="1" onClick={()=> setMin(70)}>70 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" onClick={()=> setMin(90)}>90 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={()=> setMin(120)}>120 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={()=> setMin(150)}>150 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={()=> setMin(220)}>220 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={()=> setMin(250)}>250 credits</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                                            <Dropdown.Toggle>{max ? (max + ' credits') : "Maximum credits"}</Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdownMenu">
                                                <Dropdown.Item eventKey="1" onClick={()=> setMax(70)}>70 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" onClick={()=> setMax(90)}>90 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={()=> setMax(120)}>120 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={()=> setMax(150)}>150 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={()=> setMax(220)}>220 credits</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={()=> setMax(250)}>250 credits</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                <Form.Control value={motivation} as="textarea" className="shadow-none text-light mb-3 motivationInput" placeholder="Your motivations about your next talk" onChange={(e) => { setMotivation(e.currentTarget.value); }} />
                                {/* <Form.Control value={keys} className="shadow-none text-light" placeholder="Keywords" onKeyPress={handleKeyPress} onChange={(e) => {setKeys(e.currentTarget.value); }} />
                                    <div id='badgePill'>
                                    {
                                        listKeys.map((item) => {
                                            return (
                                                <Badge pill className="selectPill p-2 mx-2 mt-2" onClick={() => {
                                                    let id = listKeys.indexOf(item);
                                                    let tmp = [...listKeys];
                                                    tmp.splice(id, 1);
                                                    setListKeys(tmp);
                                                }}>
                                                    {item}
                                                </Badge>
                                            )
                                        })
                                    }
                                    </div> */}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="button shadow-none upperTxt text-light font-weight-bold" disabled={!country && employees.val == -1  && !lang && business.length <= 0 && !turn.val && !motivation && !industry} onClick={async () => {
                            // <Button className="button shadow-none" onClick={async () => {
                                // setLoading(true);
                                setQcm(false);
                                //TODO save info 
                                saveQcm();
                                getMentors()
                            }}>Send</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={show} onHide={()=>{setShow(false);}} size="lg" centered id="modalMember">
                        <Modal.Body>
                            <p className="roboto mx-auto text-center mb-0">Get Access</p>
                            <h3 className=" mx-auto text-center mb-0">Receive inside access to</h3>
                            <h3 className=" mx-auto text-center mb-4">Over 100 exclusives mentors & events</h3>
                        <Form>
                            <Form.Label className="text-uppercase">Full name</Form.Label>
                            <Form.Control className="mb-3 text-light shadow-none" value={name} onChange={(e) => {setName(e.currentTarget.value);}} placeholder="Enter your full name" />
                            <Form.Label className="text-uppercase">Email </Form.Label>
                            <Form.Control className="mb-3 text-light shadow-none" value={emailForm} onChange={(e) => {setEmailForm(e.currentTarget.value);}} placeholder="Enter your email address" />
                            <Form.Label className="text-uppercase">Professional title</Form.Label>
                            <Form.Control className="mb-3 text-light shadow-none" value={titlePro} onChange={(e) => {setTitlePro(e.currentTarget.value);}} placeholder="Enter your title" />
                            <Form.Label className="text-uppercase">Company</Form.Label>
                            <Form.Control className="mb-3 text-light shadow-none" value={society} onChange={(e) => {setSociety(e.currentTarget.value);}} placeholder="Enter your company" />
                        </Form>
                        <Row>
                            <Button className="mx-auto w-25 button btnlogin p-2 mt-3" disabled={(!name && !society && !titlePro && !emailForm) ? true : false} onClick={()=>{
                                setShow(false);

                                sendEmail('Membership request',
                                `Nom: ${name}
                                titre: ${titlePro}
                                Société: ${society}
                                Email: ${email}`);

                                setThanks(true)
                                }}>
                                <p className="m-0 text-uppercase">Submit</p>
                            </Button>
                        </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={thanks} onHide={()=>{setThanks(false);}} size="lg" centered  id="modalEvent">
                        <Modal.Header closeButton>
                            <Modal.Title className="roboto">Received</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4 className="robotoLight">We are delighted to receive your application for the GetBigFast Club !</h4>
                            <h4 className="robotoLight">Our relationship manager will get back to you shortly.</h4>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>{setThanks(false);}}>
                            <p className="m-0 robotoLight">Cancel</p>
                            </Button>
                        </Modal.Footer>
                        </Modal>
                    {/* {CropImg(cropPic, setCroppedImage, setCropPic, file, setCrop, crop, setImageRef, imageRef)} */}
                </Container>
            }
        </Col>
    );
}

export default Login;


{/* <Row>
    <Col>
        <h4 className="pb-2">Question Loremipsum 3</h4>
        <label class="checkContainer">Nine
        <input type="checkbox" />
        <span class="checkmark"></span>
        </label>
        <label class="checkContainer">Ten
        <input type="checkbox" />
        <span class="checkmark"></span>
        </label>
        <label class="checkContainer">Eleven
        <input type="checkbox" />
        <span class="checkmark"></span>
        </label>
        <label class="checkContainer">Twelve
        <input type="checkbox" />
        <span class="checkmark"></span>
        </label>
    </Col>
</Row> */}


{/* <div className='mb-2' id='badgePill'>
    {
        languages.map((item, idx) => {
            return (
                <Badge pill className={lang.includes(item) ? 'selectPill p-2  mx-2 mt-2' : 'unselectPill p-2  mx-2 mt-2'}  onClick={() => {
                    let id = lang.indexOf(item);
                    let tmp = [...lang];
                    if (id < 0)
                        setLang(lang.concat(item));
                    else {
                        tmp.splice(id, 1);
                        setLang(tmp);
                    }
                }}>
                {item}
                </Badge>
            )
        })
    }
</div> */}

{/* <div className='mb-2' id='badgePill'>
{
    listIndustry.map((item, idx) => {
        return (
            <Badge pill className={industry.includes(item) ? 'selectPill p-2  mx-2 mt-2' : 'unselectPill p-2  mx-2 mt-2'}  onClick={() => {
                let id = industry.indexOf(item);
                let tmp = [...industry];
                if (id < 0)
                    setIndustry(industry.concat(item));
                else {
                    tmp.splice(id, 1);
                    setIndustry(tmp);
                }
            }}>
            {item}
            </Badge>
        )
    })
}
</div> */}


{/* <h5 className="pt-4 mb-0">Company based in</h5> */}