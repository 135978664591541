
import React, {useState, useEffect} from 'react';

const UserContext = React.createContext();

export const getIntialState = (storageKey) => {
  const savedState = window.localStorage.getItem(storageKey);
  try {
  if (!savedState) {
  return undefined;
  }
  return JSON.parse(savedState || {});
  } catch (e) {
  return undefined;
  }
 }

const UserProvider = (props) => {
    const [userState, setUserState] = useState(getIntialState('userState'));

    useEffect(() => {
      window.localStorage.setItem('userState', JSON.stringify(userState));
    }, [userState])

  return (
    <UserContext.Provider value={[userState, setUserState]}>
      {props.children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };