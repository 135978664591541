import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import { UserContext } from "../Context/UserContext";
import check from '../Assets/check.png';
import {showNotif} from '../Component/Utils';
import {buyRequest, buyPlan} from '../Services/user.service'
import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_51L2acwGwnkkQARIqkPBNFXedpiPz7o4J0XE2xZ0Ft7UXJB1zhHef3WPaqBlC0fWKTFj9jBepXbGgUbmlwiqi6trv00jpFPArLN');
const stripePromise = loadStripe('pk_live_51L2acwGwnkkQARIqsjZaW4tqSDUo6zvIj9JI2XkkCDNnDtjV5XFIymF8AGvknExIXNNTNA4SoV9BQbXAZ5EMYUZ200BOnxQoVm');

const Credit = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const packs = [{nb : 100, price: 290, label: 'JEFF', save: '', txt: "What's dangerous is not to evolve"}, {nb : 250, price: 590, label: 'ELON', save: '(50€ credits offered)', txt: "Great companies are built on great products"},
                    {nb : 500, price: 990, label: 'STEVE', save: '(249€ credits offered)', txt: "Stay hungry, stay foolish"}];
    useEffect(() => {
        if (!userState || !userState.token)
            props.history.push('/login');

       
        }, []);

    return (
        <Row className="fullScreen mx-0 justify-content-center">
        {
            packs.map((elem, idx) => {
                    return (
                        <Col md={'auto'} className="align-items-center align-middle my-auto">
                            <Card key={idx} className={'mx-3 cardPackSelect'}
                                style={{ width: '18rem' }}>
                                <Card.Body style={{position: 'relative'}}>
                                    <p className="text-light text-center upperTxt">{elem.label} - {elem.nb} {elem.nb > 1 ? 'Credits' : 'Credit'}</p>
                                    <p className="text-light text-center">"{elem.txt}."</p>
                                    {/* <h1 className="text-light text-center">{elem.nb}</h1> */}
                                    {/* <p className="text-light text-center">{elem.nb > 1 ? 'Credits' : 'Credit'}</p> */}
                                    <p className="text-light text-center upperTxt">${elem.price}</p>
                                    {/* {
                                        elem.save ?
                                        <p className="text-light text-center">{elem.save}</p>
                                        :
                                        <p className="text-light text-center hideT">no promotion</p>
                                    } */}
                                    <Row className="mx-0 justify-content-center mt-4 fixBot">
                                        <Button className="buttonLight mb-3 mx-auto" onClick={async () => {
                                            const stripe = await stripePromise;
                                        
                                            const session = await buyPlan(userState.token, idx);
                                        
                                            const result = await stripe.redirectToCheckout({
                                                sessionId: session.id,
                                            });
                                            if (result.error) {
                                                // If `redirectToCheckout` fails due to a browser or network
                                                // error, display the localized error message to your customer
                                                // using `result.error.message`.
                                                props.history.push({
                                                    pathname: '/info',
                                                    state: { title: 'Payment failed', msg: 'Your payment has failed please try again.' }
                                                });
                                            }
                                            else {
                                                props.history.push({
                                                    pathname: '/info',
                                                    state: { title: 'Welcome to GETBIGFAST experience !', msg: 'Now give your artists infinite options to grow 🙌' }
                                                });
                                            }
                                            // buyRequest(`Pack de ${elem.nb} XP`, userState.token) ;showNotif(false, 'Thank you', 'We have received your request and we will be in touch with you to complete de transaction.');
                                        }}>
                                            <span className="font-weight-bold text-uppercase">Buy pack</span>
                                        </Button>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    );
            })
        }
        </Row>
    );
}

export default Credit;