import React, { useEffect, useContext, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import AgoraRTC from 'agora-rtc-sdk';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {joinRoom, getEventRoom, createLive, stopLive, getEvent, streamAction } from "../Services/event.service";
import { EventContext } from "../Context/EventContext";
import { UserContext } from "../Context/UserContext";
import { LoopCircleLoading } from 'react-loadingg';
import placeholder from '../Assets/placeholder.png';
import iconPeople from '../Assets/iconPeople.png';
import Countdown from 'react-countdown';
var resourceId = null
var sId = null
var roomId = null
var streams = [];
var clientId;

var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
};

var recordings = {}

export function EventLive(props) {
  const [eventState, setEventState] = useContext(EventContext);
  const [userState] = useContext(UserContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [videoOn, setVideoOn] = useState(true);
  const [soundOn, setSoundOn] = useState(true);
  const [testing, setTesting] = useState(false);
  const [startLiveModal, setStartLiveModal] = useState(false);
  const [cameras, setCameras] = useState([]);
  const [mics, setMics] = useState([]);
  const [videoDevice, setVideoDevice] = useState(null);
  const [audioDevice, setAudioDevice] = useState(null);
  const [loading, setLoading] = useState(false);
  const iframeRef = useRef(null)
  const canvasRef = useRef(null)

  const fetchEvt = ()  => {    
    // //TODO julien 
    // return ;
    console.log('HERE');
    getEvent(userState.token, props.match.params.id)
    .then(res => {
      console.log("here2", res);
      if (res) {
            setEventState({...eventState, evt: res});
            if (eventState.evt && eventState.evt.loadingEventRoom !== true && !eventState.rooms) {
              getRoomsReq(res);
            }
        }
    }).catch((err) => {
      console.log(err);
    })
}

  const getRoomsReq = async (event) => {
    //Todo julien 
    let res = null;
    let resEvent = null;

    // let res = await getEventRoom(event._id, userState.token);
    // let resEvent = await getEvent(userState.token, props.match.params.id)
    console.log("getRoomsReq", res);
    if (res) {
      setEventState({...eventState, evt: resEvent, rooms: res});
    }
  }

  useEffect(() => {
    console.log('HELLO');
    
    if (!userState || !userState.token) {
      props.history.push('/login'); 
       return ;
    }
    if (!eventState) {
        props.history.push('/exp'); 
        return ;
    }

    //TODO par julien nouveau site getbig fast 
    fetchEvt();
    if (cameras.length === 0) {
      AgoraRTC.getDevices(function(devices){
        console.log('devices', devices);
        var cams = devices.filter(device => device.kind === 'videoinput')
        setCameras(cams);
        if (cams.length > 0) {
          setVideoDevice(cams[0].deviceId)
          if (rtc.localStream) {
            rtc.localStream.switchDevice("video", cams[0].deviceId, console.log,console.log)
          }
        }
        var mics = devices.filter(device => device.kind === 'audioinput');
        setMics(mics);
        if (mics.length > 0) {
          setAudioDevice(mics[0].deviceId)
          if (rtc.localStream) {
            rtc.localStream.switchDevice("audio", mics[0].deviceId, console.log,console.log)
          }
        }
        
      });
    }
    if (eventState.evt) {
      var intervalRef = setInterval(async() => {
        getRoomsReq(eventState.evt);
      }, 5000);
    }
    console.log('client', rtc.client);
    
    if (eventState && eventState.evt && eventState.evt.state === 'SAS' && modalIsOpen) {
      closeModal()
    } else if (eventState && eventState.evt && eventState.evt.state === 'NOT_STARTED' && !rtc.client) {
      console.log('AM I HERE');
      
      setTesting(true)
      rtc.client = AgoraRTC.createClient({
        mode: 'rtc',
        codec: 'h264',
      });

    
      rtc.client.init(
        'd2dd187b307e40549cd9772a0e92e432',
        // 'd2dd187b307e40549cd9772a0e92e432',
        function() {
          console.log('init success');
        },
        err => {
          console.error(err);
        },
      );
    rtc.client.join(
      null,
      eventState.evt._id,
      userState.user.agoraId,
      function(uid) {
        console.log(
          `${'join channel: ' + ' success, uid: '}${uid}`,
        );
        rtc.params.uid = uid;

        rtc.localStream = AgoraRTC.createStream({
          streamID: rtc.params.uid,
          audio: true,
          video: true,
          screen: false,
        });
        // if (videoDevice) {
        //   rtc.localStream.switchDevice("video", videoDevice, console.log,console.log)
        // }
        // if (audioDevice) {
        //   rtc.localStream.switchDevice("audio", audioDevice, console.log,console.log)
        // }

        rtc.localStream.setAudioProfile("high_quality_stereo");
        rtc.localStream.setVideoProfile("720p");
        rtc.localStream.init(
          function() {
            console.log('init local stream success');
            // play stream with html element id "local_stream"
            rtc.localStream.play('local_stream_test');

            rtc.client.publish(rtc.localStream, function(err) {
              console.log('publish failed');
              console.error(err);
            });
          },
          function(err) {
            console.error('init local stream failed ', err);
          },
        );
      },
      function(err) {
        console.error('client join failed', err);
      },
    );
    }
    return async () => {
      if (rtc.localStream) {
        rtc.localStream.close();
        rtc.client.unpublish(rtc.localStream)
        await rtc.client.leave();
      }
      if (intervalRef) {
        clearInterval(intervalRef)
      }
    }
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    leaveRoom();
    setIsOpen(false);
  }

  async function leaveRoom() {
    if (isLive) {
      console.log(rtc.client);
      console.log(rtc);
      // var res = await recording_stop(roomId, clientId, resourceId, sId)
      // console.log(res);
      
      rtc.client.leave(
        function() {
          // Stop playing the local stream
          rtc.localStream.stop();
          // Close the local stream
          rtc.localStream.close();
          // Stop playing the remote streams and remove the views
          while (rtc.remoteStreams.length > 0) {
            var stream = rtc.remoteStreams.shift();
            var id = stream.getId();
            stream.stop();
            // removeView(id);
          }
          streams = [];
          console.log('client leaves channel success');
        },
        function(err) {
          console.log('channel leave failed');
          console.error(err);
        },
      );
      setIsLive(false);
      setSelectedRoom(null);
    }
  }

  async function startLive() {
    streams = []
    if (!rtc.client) {
      console.log('hjere');
      console.log('hjere2');

      rtc.client = AgoraRTC.createClient({
        mode: 'rtc',
        codec: 'h264',
      });

      rtc.client.on('stream-added', function(evt) {
        const remoteStream = evt.stream;
        const id = remoteStream.getId();
        if (id !== rtc.params.uid) {
          rtc.client.subscribe(remoteStream, function(err) {
            console.log('stream subscribe failed', err);
          });
        }
        console.log('stream-added remote-uid: ', id);
      });

      rtc.client.on('stream-subscribed', async function(evt) {

        const remoteStream = evt.stream;
        const id = remoteStream.getId();
        console.log('stream-subscribed remote-uid: ', id);
        console.log(remoteStream);
        streams.push(remoteStream);
        remoteStream.play(`remote_video_live_1`);
      });

      rtc.client.on('stream-removed', async function(evt) {
        var remoteStream = evt.stream;
        var id = remoteStream.getId();
        // Stop playing the remote stream.
        var idx = streams.findIndex(itm => itm.getId() === id);
        remoteStream.stop('remote_video_live_1');
        streams.splice(idx, 1);

        if (recordings[id]) {
          // var resRecord = await recording_stop(eventState.evt._id, recordings[id].userCID, recordings[id].resourceId, recordings[id].sId)          
        }
        // Remove the view of the remote stream.
        console.log('stream-removed remote-uid: ', id);
      });

      rtc.client.on('peer-leave', async function(evt) {
        var remoteStream = evt.stream;
        if (remoteStream) {
          var id = remoteStream.getId();
          remoteStream.stop('remote_video_live_1');
          console.log('peer-leave:  ', id);
        }
      });

      // rtc.client.on('enable-local-video', function(evt) {
      //   console.log(evt);
      //   var idx = streams.findIndex(
      //     itm => itm.getId() == evt.uid,
      //   );

      //   // streams[idx].videoEnabled = true;
      //   console.log('enable-local-video', id);
      // });

      // rtc.client.on('disable-local-video', function(evt) {
      //   console.log(evt);
      //   var idx = streams.findIndex(
      //     itm => itm.getId() == evt.uid,
      //   );

      //   // streams[idx].videoEnabled = false;
      //   console.log('disable-local-video', id);
      // });
      // Initialize the client
      rtc.client.init(
        'd2dd187b307e40549cd9772a0e92e432',
        // 'd2dd187b307e40549cd9772a0e92e432',
        function() {
          console.log('init success');
        },
        err => {
          console.error(err);
        },
      );
    }
    console.log("WASSUOP");
    
    setIsLive(true)
    clientId = Math.round(Math.random() * (2000000000 - 1000000000) + 1000000000)
    roomId = eventState.evt._id
    // var res = await recording_acquire(eventState.evt._id, clientId)
    // res = JSON.parse(res);
    // console.log(res.resourceId);
    // var res = await recording_start(eventState.evt._id, clientId, res.resourceId)
    // console.log(res);
    // res = JSON.parse(res);
    // resourceId = res.resourceId
    // sId = res.sid

    rtc.client.join(
      null,
      eventState.evt._id,
      userState.user.agoraId,
      function(uid) {
        console.log(
          `${'join channel: ' + ' success, uid: '}${uid}`,
        );
        rtc.params.uid = uid;

        
                    var mediaStream = iframeRef.current ? iframeRef.current.captureStream(60) : null
                    // iframeRef.current.play();
                    // rtc.localStream.replaceTrack( mediaStream.getVideoTracks()[0]);
                    // rtc.localStream.replaceTrack( mediaStream.getAudioTracks()[0]);

                    rtc.localStream = AgoraRTC.createStream({
                      streamID: rtc.params.uid,
                      audio: true,
                      video: true,
                      screen: false,
                      ...(eventState.evt.recordedVideo ? {
                      audioSource: mediaStream.getAudioTracks()[0],
                      videoSource: mediaStream.getVideoTracks()[0]
                      } : {})
                    });


        rtc.localStream.setAudioProfile("high_quality_stereo");
        rtc.localStream.setVideoProfile("1080p");
        rtc.localStream.init(
          function() {
            console.log('init local stream success');
            // play stream with html element id "local_stream"
            rtc.localStream.play('local_stream_live');

            setTimeout(() => {
              // if (videoDevice) {
              //   rtc.localStream.switchDevice("video", videoDevice, console.log,console.log)
              // }
              // if (audioDevice) {
              //   rtc.localStream.switchDevice("audio", audioDevice, console.log,console.log)
              // }    
  
            }, 2000)

            rtc.client.publish(rtc.localStream, function(err) {
              console.log('publish failed');
              console.error(err);
            });
          },
          function(err) {
            console.error('init local stream failed ', err);
          },
        );
      },
      function(err) {
        console.error('client join failed', err);
      },
    );
  }

  // function removeView(input) {
  //   document.getElementById('content').removeChild(input.parentNode);
  // }

  function recording_acquire(channelName, clientId) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic MmQxYzBiYzBmYzAzNDVkOWExYTA1ZGRmYjIxZjRlYzg6NTU2ZGQwMDVmNGU0NDhjZThjMTAyODIwMTYxYWI0NWI=");
  
      var raw = JSON.stringify({"cname": channelName,"uid": "" + clientId,"clientRequest":{}});
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      console.log(requestOptions);
      
  
      fetch("https://api.agora.io/v1/apps/d2dd187b307e40549cd9772a0e92e432/cloud_recording/acquire", requestOptions)
    .then(response => response.text())
    .then(result => resolve(result))
    .catch(error => reject(error));  
    })
  }

  function recording_start(channelName, clientId, resourceId) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic MmQxYzBiYzBmYzAzNDVkOWExYTA1ZGRmYjIxZjRlYzg6NTU2ZGQwMDVmNGU0NDhjZThjMTAyODIwMTYxYWI0NWI=");
      
      var raw = {
        "cname":channelName,
        "uid":"" + clientId,
        "clientRequest":{
          "recordingConfig":{
            "maxIdleTime": 30,
            "streamTypes": 2,
            "audioProfile": 1,
            "channelType": 0, 
            "videoStreamType": 1, 
            "transcodingConfig": {
                "height": 1920, 
                "width": 1080,
                "bitrate": 4780, 
                "fps": 60, 
                "mixedVideoLayout": 3,
                "layoutConfig": [
                  {
                       "uid": "" + userState.user.agoraId,
                       "x_axis": 0,
                       "y_axis": 0,
                       "width": 1,
                       "height": 0.5,
                       "alpha": 1.0,
                       "render_mode": 0
                   },
                  {
                       "x_axis": 0,
                       "y_axis": 0.5,
                       "width": 0.5,
                       "height": 0.5,
                       "alpha": 1.0,
                       "render_mode": 0
                   },
                   {
                    "x_axis": 0.5,
                    "y_axis": 0.5,
                    "width": 0.5,
                    "height": 0.5,
                    "alpha": 1.0,
                    "render_mode": 0
                  }
                   ]
                // "maxResolutionUid": "" + userState.user.agoraId
               },
            "subscribeVideoUids": ["#allstream#"], 
            "subscribeAudioUids": ["#allstream#"],
            "subscribeUidGroup": 0
            },
          "storageConfig":{
            "vendor":1,
            "region":6,
            "bucket":"akius-recording",
            "accessKey":"AKIATJW2VQE5ZCOEN4PS",
            "secretKey":"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",
            fileNamePrefix: [eventState.evt._id, channelName]
          }	
        }
      } 
      
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: 'follow'
      };
      
      fetch(`https://api.agora.io/v1/apps/d2dd187b307e40549cd9772a0e92e432/cloud_recording/resourceid/${resourceId}/mode/mix/start`, requestOptions)
      .then(response => response.text())
      .then(result => resolve(result))
      .catch(error => reject(error));  
    });
  }

  function recording_user_start(channelName, clientId, resourceId, userUid) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic MmQxYzBiYzBmYzAzNDVkOWExYTA1ZGRmYjIxZjRlYzg6NTU2ZGQwMDVmNGU0NDhjZThjMTAyODIwMTYxYWI0NWI=");
      
      var raw = {
        "cname":channelName,
        "uid":"" + clientId,
        "clientRequest":{
          "recordingConfig":{
            "maxIdleTime": 30,
            "streamTypes": 2,
            "audioProfile": 1,
            "channelType": 0, 
            "videoStreamType": 1, 
            "transcodingConfig": {
                "height": 1920, 
                "width": 1080,
                "bitrate": 4780, 
                "fps": 60, 
                "mixedVideoLayout": 3,
                "layoutConfig": [
                  {
                       "uid": "" + userState.user.agoraId,
                       "x_axis": 0,
                       "y_axis": 0,
                       "width": 1,
                       "height": 0.5,
                       "alpha": 1.0,
                       "render_mode": 0
                   },
                  {
                    "uid": "" + userUid,
                       "x_axis": 0.25,
                       "y_axis": 0.5,
                       "width": 0.5,
                       "height": 0.5,
                       "alpha": 1.0,
                       "render_mode": 0
                   }
                   ]
                // "maxResolutionUid": "" + userState.user.agoraId
               },
            "subscribeVideoUids": ["#allstream#"], 
            "subscribeAudioUids": ["#allstream#"],
            "subscribeUidGroup": 0
            },
          "storageConfig":{
            "vendor":1,
            "region":6,
            "bucket":"akius-recording",
            "accessKey":"AKIATJW2VQE5ZCOEN4PS",
            "secretKey":"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",
            fileNamePrefix: [eventState.evt._id, userUid]
          }	
        }
      } 
      
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: 'follow'
      };
      
      fetch(`https://api.agora.io/v1/apps/d2dd187b307e40549cd9772a0e92e432/cloud_recording/resourceid/${resourceId}/mode/mix/start`, requestOptions)
      .then(response => response.text())
      .then(result => resolve(result))
      .catch(error => reject(error));  
    });
  }

  function recording_stop(channelName, clientId, resourceId, sid) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json;charset=utf-8");
      myHeaders.append("Authorization", "Basic MmQxYzBiYzBmYzAzNDVkOWExYTA1ZGRmYjIxZjRlYzg6NTU2ZGQwMDVmNGU0NDhjZThjMTAyODIwMTYxYWI0NWI=");
      
      var raw = JSON.stringify({"cname": channelName,"uid": "" + clientId,"clientRequest":{}});
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
        fetch(`https://api.agora.io/v1/apps/d2dd187b307e40549cd9772a0e92e432/cloud_recording/resourceid/${resourceId}/sid/${sid}/mode/mix/stop`, requestOptions)
        .then(response => response.text())
      .then(result => resolve(result))
      .catch(error => reject(error));  
    });
  }

  const streamActionReq = async (action) => {
    setLoading(true);
    setStartLiveModal(false);
    console.log("hello");

    if (action == 'ROOMS' && rtc.client) {
      rtc.client.leave(
        function() {
          // Stop playing the local stream
          rtc.localStream.stop();
          // Close the local stream
          rtc.localStream.close();
          // Stop playing the remote streams and remove the views
          while (rtc.remoteStreams.length > 0) {
            var stream = rtc.remoteStreams.shift();
            var id = stream.getId();
            stream.stop();
            // removeView(id);
          }
          streams = [];
          console.log('client leaves channel success');
          setTesting(false)
  
        },
        function(err) {
          console.log('channel leave failed');
          console.error(err);
        },
      );      
    }

    let res = await streamAction(eventState.evt._id, userState.token, action);
    console.log(res);
    
    if (res) {
      setEventState({...eventState, evt: res});
    }
    setLoading(false);
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    // if (completed) {
      // Render a completed state
      return (<Button className="button shadow-none mb-4" onClick={() => {setStartLiveModal(true)}}>
                  Start
              </Button>);
    // } else {
    //   // Render a countdown
    //   return <span style={{color: 'white'}}>The live will start in {hours}h {minutes}m {seconds}s</span>;
    // }
  };

  console.log(eventState);
  

  return (
    <Container fluid>
      <Row className=" mx-0 justify-content-center">
        <Col className="darkCard m-0 mr-3 pb-3" xs={3}>
          <p className="text-light mt-2 font-weight-bold mb-3">Select the camera you want to use</p>          
          {cameras.map((camera) => 
            <p className={`px-2 py-1 pillTxtEdit mt-auto ml-2 text-light mb-1 ${videoDevice === camera.deviceId ? 'badgeBlue' : 'pillTxtEditDis'}`}
            onClick={() => {
              console.log(camera);
              setVideoDevice(camera.deviceId)
              rtc.localStream.switchDevice("video", camera.deviceId, console.log,console.log)
            }}>
              {camera.label}
            </p>
          )}
          <p className="text-light mt-2 font-weight-bold  mb-3">Select the microphone you want to use</p>                    
          {mics.map((mic) => 
            <p className={`px-2 py-1 pillTxtEdit mt-auto ml-2 text-light mb-1 ${audioDevice == mic.deviceId ? 'badgeBlue' : 'pillTxtEditDis'}`}
            onClick={() => {
              console.log(mic);
              setAudioDevice(mic.deviceId)
              rtc.localStream.switchDevice("audio", mic.deviceId, console.log,console.log)
            }}>
              {mic.label}
            </p>
          )}
        </Col>
        <Container className="darkCard w-100 m-0">
          {eventState.evt && eventState.evt.state === 'ROOMS' ? (
            <>
              <p className="text-light text-center m-3 mx-auto">Navigate on the map and visit your fans during Ak'Time</p>        
              <div className="mb-3 mx-auto" style={{ height: 600, width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyB-zobi26z95woGjmbxejRjn69Hk49AID4',
                  }}
                  options={{ styles: mapStyles }}
                  center={{ lat: 43.2803051, lng: 5.2404128 }}
                  zoom={11}
                >
                  {eventState.rooms &&
                    eventState.rooms.map(room => (
                      <img
                        alt="desc"
                        onClick={() => {
                          setIsOpen(true);
                          setSelectedRoom(room);
                          return;
                        }}
                        style={{
                          backgroundColor: 'red',
                          width: 30,
                          height: 30,
                          borderRadius: 15,
                        }}
                        lat={room.owner.latitude}
                        lng={room.owner.longitude}
                        src={room.owner.picture || placeholder}
                        text="My Marker"
                      />
                    ))}
                </GoogleMapReact>
              </div>
            </>
          ) : eventState.evt && eventState.evt.state === 'NOT_STARTED' ? (
            <>
              <div className="text-light font-weight-bold  tex-center m-3 mx-auto">Live will start soon, do not refresh.</div>
              <div id="local_stream_test" className="m-3 mx-auto" style={{ height: 500, width: 300 }} />
              {/* <div
                onClick={() => {
                  history.push({ pathname: `/test_event/${event.event._id}`, state: event.event });
                }}
                style={{ color: 'white', backgroundColor: '#454FFE', padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 5, marginLeft: 7}}>
                Test Client
                </div> */}
            </>
          ) : eventState.evt && eventState.evt.state === 'OVER' ? (
            <>
              <div lassName="text-light text-center m-3">
                Live has ended
              </div>
            </>
          ) : eventState.evt && eventState.evt.state === 'SAS' ? (
            <>
              <div className="text-light text-center m-3 mx-auto">
                Live is about to start, press the start button when you are ready
              </div>
              <div style={{ height: 600, width: '100%' }} />
            </>
          ) : (
            eventState.evt && (
              <>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 15}}>
                <div
                  style={{ color: 'white', backgroundColor: 'red', padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 5, marginRight: 7}}
                >
                  Live
                </div>
                </div>
                <Row className="mx-0 justify-content-center">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      id="remote_video_live_1"
                      style={{ width: 650, height: 300, marginTop: 20 }}
                    >
                    </div>
                </div>
                </Row>
                <Row className="mx-0 justify-content-center">
                  <div id="local_stream_live" style={{ width: 650, height: 300}} />
                </Row>


                {rtc.client == null &&
                <>
                    <Row className="mx-0 justify-contentt-center pb-3">
                <Button className="button mx-auto"
                onClick={() => {
                  console.log(rtc.client);
                 startLive()
                }} >
                Connect to Live
                </Button>
                </Row>
                </>
                }
                {/* {rtc.localStream &&  */}
                <Row className="mx-0 justify-content-center">
                  <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
                    {/* <i onClick={async () => {
                      let res = await stopLive(
                        eventState.evt._id,
                        userState.token)
                      // var resRecord = await recording_stop(roomId, clientId, resourceId, sId)
                      // console.log(resRecord);
                      

                      setTimeout(() => {
                        rtc.client.leave(
                          function() {
                            // Stop playing the local stream
                            rtc.localStream.stop();
                            // Close the local stream
                            rtc.localStream.close();
                            // Stop playing the remote streams and remove the views
                            while (rtc.remoteStreams.length > 0) {
                              var stream = rtc.remoteStreams.shift();
                              var id = stream.getId();
                              stream.stop();
                              // removeView(id);
                            }
                            streams = [];
                            console.log('client leaves channel success');
                          },
                          function(err) {
                            console.log('channel leave failed');
                            console.error(err);
                          },
                        );
                      }, 2000)
                    }} style={{color: 'white', margin: 10}} class="fas fa-stop fa-2x" /> */}
                    <i onClick={() => {
                      if (soundOn === true) {
                        rtc.localStream.disableAudio();
                        setSoundOn(false)
                      } else {
                        rtc.localStream.enableAudio();
                        setSoundOn(true)
                      }
                    }} style={{color: 'white', margin: 10}} class={`fas ${soundOn === false ? 'fa-microphone-slash' : 'fa-microphone'} fa-2x`} />
                    <i onClick={() => {
                      if (videoOn === true) {
                        rtc.localStream.disableVideo();
                        setVideoOn(false)
                      } else {
                        rtc.localStream.enableVideo();
                        setVideoOn(true)
                      }
                    }} style={{color: 'white', margin: 10}} class={`fas ${videoOn === false ? 'fa-video-slash' : 'fa-video'} fa-2x`} />
                  </div>
                  </Row>
                {/* } */}
                </>
            )
          )}
                    <Col align="center">
            {
              eventState.evt && eventState.evt.state === 'NOT_STARTED' && 
              <Countdown renderer={renderer} date={new Date(eventState.evt.date * 1000)}>
              </Countdown>
            }
            {/* {
              eventState.evt && eventState.evt.state === 'ROOMS' &&
              <Button className="button shadow-none mb-4" onClick={() => {streamActionReq('SAS')}}>
                  SAS
              </Button>
            } */}
            {
              eventState.evt && eventState.evt.state === 'SAS' &&
              <Button className="button shadow-none mb-4" onClick={async () => {
                console.log('here');
                streams = []

                streamActionReq('LIVE')
                // let resCreate = await createLive(
                //   eventState.evt._id,
                //   userState.token
                // );
                // setEventState({...eventState, evt: {...eventState.evt, state: 'LIVE'}});
                // console.log(resCreate)

                rtc.client = AgoraRTC.createClient({
                  mode: 'rtc',
                  codec: 'h264',
                });

                rtc.client.on('stream-added', function(evt) {
                  const remoteStream = evt.stream;
                  const id = remoteStream.getId();
                  if (id !== rtc.params.uid) {
                    rtc.client.subscribe(remoteStream, function(err) {
                      console.log('stream subscribe failed', err);
                    });
                  }
                  console.log('stream-added remote-uid: ', id);
                });

                rtc.client.on('stream-subscribed', async function(evt) {
                  const remoteStream = evt.stream;
                  const id = remoteStream.getId();
                  console.log('stream-subscribed remote-uid: ', id);

                  var userCID = Math.round(Math.random() * (2000000000 - 1000000000) + 1000000000)

                  var res = await recording_acquire(eventState.evt._id, userCID)
                  res = JSON.parse(res);
                  console.log(res.resourceId);
                  var res = await recording_user_start(eventState.evt._id, userCID, res.resourceId, "" + id)
                  console.log(res);
                  res = JSON.parse(res);
                  recordings[id] = {resourceId: res.resourceId, sId: res.sid, userCID}
          
                  // Add a view for the remote stream.
                  //                  addView(id);
                  // Play the remote stream.
                  //      addView(id)
                  streams.push(remoteStream);
                  remoteStream.play(`remote_video_live_1`);
                });

                rtc.client.on('stream-removed', async function(evt) {
                  var remoteStream = evt.stream;
                  var id = remoteStream.getId();
                  // Stop playing the remote stream.

                  if (recordings[id]) {
                    // var resRecord = await recording_stop(eventState.evt._id, recordings[id].userCID, recordings[id].resourceId, recordings[id].sId)          
                  }
          
                  var idx = streams.findIndex(itm => itm.getId() === id);
                  remoteStream.stop('remote_video_live_1');
                  streams.splice(idx, 1);
                  // Remove the view of the remote stream.
                  console.log('stream-removed remote-uid: ', id);
                });

                rtc.client.on('enable-local-video', function(evt) {
                  console.log(evt);
                  var idx = streams.findIndex(
                    itm => itm.getId() === evt.uid,
                  );

                  // streams[idx].videoEnabled = true;
                  // console.log('enable-local-video', id);
                });

                rtc.client.on('disable-local-video', function(evt) {
                  console.log(evt);
                  var idx = streams.findIndex(
                    itm => itm.getId() === evt.uid,
                  );

                  // streams[idx].videoEnabled = false;
                  // console.log('disable-local-video', id);
                });
                // Initialize the client
                rtc.client.init(
                  'd2dd187b307e40549cd9772a0e92e432',
                  // 'd2dd187b307e40549cd9772a0e92e432',
                  function() {
                    console.log('init success');
                  },
                  err => {
                    console.error(err);
                  },
                );

    clientId = Math.round(Math.random() * (2000000000 - 1000000000) + 1000000000)
                
                roomId = eventState.evt._id
                var res = await recording_acquire(eventState.evt._id, clientId)
                res = JSON.parse(res);
                console.log(res.resourceId);
                var res = await recording_start(eventState.evt._id, clientId, res.resourceId)
                console.log(res);
                res = JSON.parse(res);
                resourceId = res.resourceId
                sId = res.sid
                rtc.client.join(
                  null,
                  eventState.evt._id,
                  userState.user.agoraId,
                  function(uid) {
                    console.log(
                      `${'join channel: ' + ' success, uid: '}${uid}`,
                    );
                    rtc.params.uid = uid;

                    var mediaStream = iframeRef.current ? iframeRef.current.captureStream(60) : null
                    // iframeRef.current.play();
                    // rtc.localStream.replaceTrack( mediaStream.getVideoTracks()[0]);
                    // rtc.localStream.replaceTrack( mediaStream.getAudioTracks()[0]);

                    rtc.localStream = AgoraRTC.createStream({
                      streamID: rtc.params.uid,
                      audio: true,
                      video: true,
                      screen: false,
                      ...(eventState.evt.recordedVideo ? {
                        audioSource: mediaStream.getAudioTracks()[0],
                        videoSource: mediaStream.getVideoTracks()[0]
                        } : {})  
                      // audioSource: mediaStream.getAudioTracks()[0],
                      // videoSource: mediaStream.getVideoTracks()[0]
                    });
                    // if (videoDevice) {
                    //   rtc.localStream.switchDevice("video", videoDevice, console.log,console.log)
                    // }
                    // if (audioDevice) {
                    //   rtc.localStream.switchDevice("audio", audioDevice, console.log,console.log)
                    // }
            
                    rtc.localStream.setAudioProfile("high_quality_stereo");
                    rtc.localStream.setVideoProfile("1080p");
                    rtc.localStream.init(
                      function() {
                        setTimeout(() => {
                          console.log('init local stream success');
                          // play stream with html element id "local_stream"
                          rtc.localStream.play('local_stream_live');
  
                          // if (videoDevice) {
                          //   rtc.localStream.switchDevice("video", videoDevice, console.log,console.log)
                          // }
                          // if (audioDevice) {
                          //   rtc.localStream.switchDevice("audio", audioDevice, console.log,console.log)
                          // }
                          
                          rtc.client.publish(rtc.localStream, function(err) {
                            console.log('publish failed');
                            console.error(err);
                          });
                        }, 2000)
                      
                      },
                      function(err) {
                        console.error('init local stream failed ', err);
                      },
                    );
                  },
                  function(err) {
                    console.error('client join failed', err);
                  },
                );
              }}>
                  Start live
              </Button>
            }
          </Col>
        </Container>
      </Row>
          <Modal centered show={startLiveModal} onHide={() => {setStartLiveModal(false)}}>
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                      Are you sure to start the live?
                  </Modal.Title>
              </Modal.Header>
              <Modal.Footer className="pt-0">
                <Button className="button" onClick={ () => {streamActionReq('SAS')}}>Start</Button>
                <Button className="buttonDisable" onClick={() => {setStartLiveModal(false)}}>Cancel</Button>
              </Modal.Footer>
          </Modal>
          <Modal centered show={loading} className="modalLoading">
              <LoopCircleLoading color="#FA923A" size={'large'} />  
          </Modal>
    </Container>
  );
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1e1e1e',
  },
};

const mapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8ec3b9',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1a3646',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4b6878',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#64779e',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4b6878',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#334e87',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#283d6a',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#6f9ba5',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3C7680',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#304a7d',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#98a5be',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#2c6675',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#255763',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#b0d5ce',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#98a5be',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#283d6a',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3a4762',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#0e1626',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#4e6d70',
      },
    ],
  },
];

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#app');



export default EventLive;




// <Modal
//   isOpen={modalIsOpen}
//   onRequestClose={closeModal}
//   style={customStyles}
//   contentLabel="Example Modal"
// >
//   <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
//     <h2 style={{ margin: 0, color: 'white' }}>
//       {selectedRoom ? selectedRoom.owner.username : ''}'s room
//     </h2>
//       <i onClick={closeModal} style={{color: 'white', marginLeft:'auto', marginTop: 5}} class="fas fa-times fa-2x" />
//   </div>

//   {isLive ? (
//     <div style={{ display: 'flex', flexDirection: 'column' }}>
//       <div id="local_stream" style={{ width: 600, height: 500 }} />
//       <div style={{ display: 'flex', flexDirection: 'row' }}>
//         <div style={{ position: 'relative' }}>
//           <div id="remote_video_1" style={{ width: 200, height: 300 }}>
//             {streams[0] &&
//               (streams[0].videoEnabled === false ||
//                 streams[0].video === false) && (
//                 <img
//                   alt="desc"
//                   src={placeholder}
//                   style={{
//                     width: 200,
//                     height: 300,
//                     position: 'absolute',
//                     top: 0,
//                     objectFit: 'cover',
//                   }}
//                 />
//               )}
//           </div>
//           {/* <div style={{width: '100%', height: '100%', backgroundColor: 'red'}}></div> */}
//         </div>
//         <div id="remote_video_2" style={{ width: 200, height: 300 }} />
//         <div id="remote_video_3" style={{ width: 200, height: 300 }} />
//         <div id="remote_video_4" style={{ width: 200, height: 300 }} />
//       </div>
//     </div>
//   ) : (
//     <div
//       style={{ display: 'flex', flexDirection: 'column', padding: 20 }}
//     >
//       <h3 style={{ color: 'white' }}>Participants</h3>
//       <div style={{ display: 'flex', flexDirection: 'row' }}>
//         {selectedRoom &&
//           selectedRoom.participants.map(users => {
//             return (
//               <div
//                 style={{
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   marginRight: 20,
//                 }}
//               >
//                 <img
//                   alt="desc"
//                   style={{
//                     width: 60,
//                     height: 60,
//                     borderRadius: 30,
//                   }}
//                   src={users.picture || placeholder}
//                 />
//                 <div style={{ color: 'white' }}>{users.username}</div>
//               </div>
//             );
//           })}
//       </div>
//       <div
//         onClick={() => {
//           joinRoomReq(selectedRoom);
//         }}
//         style={{ marginTop: 60, marginBottom: 10 }}
//         className="login-connectBtn"
//       >
//         Join the room
//       </div>
//     </div>
//   )}
// </Modal>