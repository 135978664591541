import React, {useContext, useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { UserContext } from "../Context/UserContext";
import { uploadProfilePic, updateProfile, addSocial, updateSocial, getUser } from "../Services/user.service";
import user from '../Assets/user.png';
import edit from '../Assets/edit.png';
import facebookIcon from '../Assets/facebook.png';
import instagramIcon from '../Assets/instagram.png';
import twitterIcon from '../Assets/twitter.png';
import tiktokIcon from '../Assets/tiktok.png';
import snapchatIcon from '../Assets/snapchat.png';
import youtubeIcon from '../Assets/youtube.png';
import logoPlain from '../Assets/logo.png';
import www from '../Assets/www.png';
import {showNotif, is_url, handleImgChange} from '../Component/Utils';
import { LoopCircleLoading } from 'react-loadingg';
import CropImg from '../Component/CropImg';

const Profile = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [editProfile, setEditProfile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editSocial, setEditSocial] = useState(false);
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [twitter, setTwitter] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [youtube, setYoutube] = useState('');
    const [merch, setMerch] = useState('');
    const [snapchat, setSnapchat] = useState('');
    const [username, setUsername] = useState('');
    const [description, setDescription] = useState('');
    const [pass, setPass] = useState('');
    const [confPass, setConfPass] = useState('');
    const [image, setImage] = useState(null);
    const [file, setFile] = React.useState(null);
    const inputOpenFileRef = React.createRef();
    const [crop, setCrop] = React.useState({aspect: 1, width: 300});
    const [cropPic, setCropPic] = React.useState(null);
    const [croppedImage, setCroppedImage] = React.useState('');
    const [imageRef, setImageRef] = React.useState('');

    useEffect(() => {
        if (!userState || !userState.token || (userState && userState.token && userState.user.acceptedCGU != true))
            props.history.push('/login');
        else if (userState && userState.token) {
            getUser(userState.token, userState.user._id)
            .then(res => {
                if (res) {
                    userState.user = res;
                    setUserState({...userState});
                }
            })
        }
    }, [props.history]);

    useEffect(() => {
        if (croppedImage) {
            setCropPic(false);
            uploadPic();
        }
    }, [croppedImage]);

    async function uploadPic() {
        if (!croppedImage)
            showNotif(true, 'Error', 'Image not saved.');
        else {
            setLoading(true);
            let res = await uploadProfilePic(croppedImage, userState.token, userState.user.artist._id);
            setLoading(false);
            if (res) {
                userState.user.artist.picture = res;
                setUserState({...userState});
                setLoading(false);;
            }
        }
    }

    function checkError() {
        let err = true;
        // var regex = /[A-Z]/;

        if (!description || !username || !pass) {
            showNotif(true, 'Error', 'Please enter the name, password and description.');
            err = false;
        }
        // if (pass && (pass.length < 8 || !regex.test(pass))) {
        //     showNotif(true, 'Error password', 'Password must be at least 8 characters and it must contains at least 1 capital letter.');
        //     err = false;
        // }
        return err;
    }

    async function updateInfo() {
        if (checkError()) {
            setLoading(true);
            let res = await updateProfile(description, username, userState.user.artist._id, userState.token, pass);
            setLoading(false);
            if (res) {
                userState.user.artist.name = username;
                userState.user.artist.description = description;
                setUserState({...userState});
                setEditProfile(false);
            }
        }
    }

    async function updateSocialMedia(name, url) {
        let res;
        let idx = userState.user.artist.socialMedias.findIndex(elem => elem.name === name);

        if (url && !is_url(url)) {
            showNotif(true, 'Error', 'Please enter a valid social media link.');
            return ;
        }
        setLoading(true);
        if (idx >= 0) {
            if (userState.user.artist.socialMedias[idx].url === url)
                {
                    showNotif(true, 'Error', 'Please enter a new link.');
                    setLoading(false);
                    return ;
                }
            res = await updateSocial(name, url, userState.user.artist.socialMedias[idx]._id, userState.token, userState.user.artist._id);
        }
        else
            res = await addSocial(name, url, userState.token, userState.user.artist._id);
        setLoading(false);
        if (res) {
            if (idx >= 0) {
                if (!url) {
                    userState.user.artist.socialMedias.splice(idx, 1);
                }
                else
                    userState.user.artist.socialMedias[idx].url = url;
            }
            else
                userState.user.artist.socialMedias.push({name: name, url: url});
            setUserState({...userState});
            setEditProfile(false);
            showNotif(false, 'Updated', 'Social media' + (!url ? ' deleted' : ' saved.'));
        }
    }

    if (!userState)
        return (
            <></>
        );

    else
    return (
         <div>
            {
            userState && userState.user ?
                <Container>
                    <Row>
                        <Col className="mx-2" align="center">
                            {
                                userState &&
                                <>
                                    <div className="darkCard">
                                        <div className="profileHeader">
                                            <Image
                                                className="avatarBg"
                                                src={userState.user.artist && userState.user.artist?.picture ? userState.user.artist.picture : logoPlain}
                                            />
                                            {
                                                <Image
                                                    className="avatar"
                                                    src={userState.user.artist && userState.user.artist.picture ? userState.user.artist.picture : logoPlain}
                                                    rounded
                                                />
                                            }
                                            <Button
                                                className="editBtn mt-4 py-2 pr-3 px-3 shadow-none"
                                                onClick={() => {
                                                    setUsername(userState.user.artist.name)
                                                    setDescription(userState.user.artist.description)
                                                    setImage(userState.user.artist.picture)
                                                    setPass(null);
                                                    setConfPass(null);
                                                    setFile(null);
                                                    setEditProfile(true);
                                                    setCroppedImage(null);
                                                }}>
                                                Edit profile
                                            </Button>
                                        </div>
                                        <h3 className="text-light text-center">{userState.user.artist && userState.user.artist.name}</h3>
                                        <p className="text-light text-center">{userState.user.email}</p>
                                        <Row className="mx-4">
                                            <p className="mx-auto text-light text-justify font-weight-light">{userState.user.artist && userState.user.artist.description}</p>
                                        </Row>
                                    </div>
                                    <Row className="mx-0">
                                        <Col className="darkCard mx-2">
                                            <Row>
                                                <p className="text-light text-center title mx-auto">Social medias</p>
                                                <Image className="editIcon mr-2 mt-1" src={edit} onClick={() => {
                                                    setFacebook(userState.user.artist.socialMedias.find(elem => elem.name === 'Facebook')
                                                    ? userState.user.artist.socialMedias.find(elem => elem.name === 'Facebook').url : null);
                                                    setInstagram(userState.user.artist.socialMedias.find(elem => elem.name === 'Instagram')
                                                    ? userState.user.artist.socialMedias.find(elem => elem.name === 'Instagram').url : null);
                                                    setSnapchat(userState.user.artist.socialMedias.find(elem => elem.name === 'Snapchat')
                                                    ? userState.user.artist.socialMedias.find(elem => elem.name === 'Snapchat').url : null);
                                                    setTiktok(userState.user.artist.socialMedias.find(elem => elem.name === 'Tiktok')
                                                    ? userState.user.artist.socialMedias.find(elem => elem.name === 'Tiktok').url : null);
                                                    setTwitter(userState.user.artist.socialMedias.find(elem => elem.name === 'Twitter')
                                                    ? userState.user.artist.socialMedias.find(elem => elem.name === 'Twitter').url : null);
                                                    setYoutube(userState.user.artist.socialMedias.find(elem => elem.name === 'Youtube')
                                                    ? userState.user.artist.socialMedias.find(elem => elem.name === 'Youtube').url : null);
                                                    setEditSocial(true);
                                                }}/>
                                            </Row>
                                            <Row className="mx-0 mb-2 justify-content-center">
                                                {
                                                    userState.user.artist && userState.user.artist.socialMedias.length > 0 ?
                                                    userState.user.artist.socialMedias.map((socialMed) => {
                                                        // eslint-disable-next-line default-case
                                                        switch(socialMed.name) {
                                                            case 'Facebook':
                                                                return (
                                                                    <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                        <Image
                                                                            className="socialIcon"
                                                                            src={facebookIcon}
                                                                        />
                                                                    </a>
                                                                )
                                                            case 'Instagram':
                                                                return (
                                                                    <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                        <Image
                                                                            className="socialIcon"
                                                                            src={instagramIcon}
                                                                        />
                                                                    </a>
                                                                )
                                                            case 'Twitter':
                                                                return (
                                                                    <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                        <Image
                                                                            className="socialIcon"
                                                                            src={twitterIcon}
                                                                        />
                                                                    </a>
                                                                )
                                                            case 'Snapchat':
                                                                return (
                                                                    <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                        <Image
                                                                            className="socialIcon"
                                                                            src={snapchatIcon}
                                                                        />
                                                                    </a>
                                                                )
                                                            case 'Tiktok':
                                                                return (
                                                                    <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                        <Image
                                                                            className="socialIcon"
                                                                            src={tiktokIcon}
                                                                        />
                                                                    </a>
                                                                )
                                                            case 'Youtube':
                                                                return (
                                                                    <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                        <Image
                                                                            className="socialIcon"
                                                                            src={youtubeIcon}
                                                                        />
                                                                    </a>
                                                                )
                                                            case 'Merch':
                                                                return (
                                                                    <a key={socialMed._id} href={socialMed.url} target="_blank" rel="noreferrer">
                                                                        <Image
                                                                            className="socialIcon"
                                                                            src={www}
                                                                        />
                                                                    </a>
                                                                )
                                                        }
                                                    }) :
                                                    <p className="text-light" style={{fontWeight: '300', fontSize: 14}}>Social media</p>
                                                }
                                            </Row>
                                        </Col>
                                        <Col className="darkCard mx-2">
                                            <p className="text-light text-center title">Credits available</p>
                                            <h2 className="text-light">{userState.user && userState.user.credits ? userState.user.credits : 0}</h2>
                                        </Col>
                                    </Row>
                                </>
                            }
                             <Button
                                className="button mt-4 py-2 pr-3 px-3 shadow-none mx-auto"
                                onClick={() => {
                                    setUserState({ user: null, token: null});
                                    props.history.push('/login');
                                }}>
                                <p className="text-light my-auto upperTxt font-weight-bold">Sign out</p>
                            </Button>
                        </Col>
                    </Row>
                </Container>
                :
                <></>
                }
                <Modal centered show={editProfile && !loading} onHide={() => setEditProfile(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        Edit your profile
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row className="mb-4">
                            <Form.File
                                ref={inputOpenFileRef}
                                className="mt-2"
                                style={{ display: 'none' }}
                                accept="image/*"
                                type="file"
                                onChange={(evt) => {handleImgChange(evt, setFile, null, setCropPic)}}
                            />
                                {
                                    (image || croppedImage) ? 
                                    <Image
                                        className="editPic mx-auto"
                                        src={croppedImage ? URL.createObjectURL(croppedImage) : image}
                                        onClick={() => {inputOpenFileRef?.current.click()}}
                                        rounded
                                    />
                                    :
                                    <div className="iconBg mx-auto" onClick={() => {inputOpenFileRef?.current.click()}}>
                                        <Image
                                            className="socialIcon"
                                            src={user}
                                        />
                                    </div>
                                }
                            </Row>
                            <Form.Control value={username} className="shadow-none text-light" placeholder="Username" onChange={(e) => { setUsername(e.currentTarget.value); }} />
                            <Form.Control value={description}  as="textarea" rows={3} className="shadow-none text-light" placeholder="Description" onChange={(e) => { setDescription(e.currentTarget.value); }} />
                            <Form.Control value={pass} type="password" className="shadow-none text-light" placeholder="Password" onChange={(e) => { setPass(e.currentTarget.value); }} />
                            {
                                pass && confPass && pass !== confPass &&
                                <p className="passwordInfo ml-2">Passwords do not match</p>
                            }
                            <Form.Control value={confPass} type="password" className="shadow-none text-light" placeholder="Password confirmation" onChange={(e) => { setConfPass(e.currentTarget.value); }} />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="button" disabled={pass != confPass} onClick={() => {updateInfo();}}>Save</Button>
                    </Modal.Footer>
                </Modal>
                {CropImg(cropPic, setCroppedImage, setCropPic, file, setCrop, crop, setImageRef, imageRef, uploadPic)}
                <Modal centered show={editSocial} onHide={() => {setEditSocial(null);}}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Add your social media
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="d-flex justify-content-center">
                            <Image className="socialIconForm my-auto" src={facebookIcon}/>
                            <Form.Control value={facebook} className=" my-auto shadow-none text-light socialInput ml-2" placeholder="Facebook link" onChange={(e) => { setFacebook(e.currentTarget.value); }} />
                            <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Facebook', facebook);}}>Save</Button>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Image className="socialIconForm my-auto" src={instagramIcon}/>
                            <Form.Control value={instagram} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Instagram link" onChange={(e) => { setInstagram(e.currentTarget.value); }} />
                            <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Instagram', instagram);}}>Save</Button>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Image className="socialIconForm my-auto" src={twitterIcon}/>
                            <Form.Control value={twitter} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Twitter link" onChange={(e) => { setTwitter(e.currentTarget.value); }} />
                            <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Twitter', twitter);}}>Save</Button>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Image className="socialIconForm my-auto" src={snapchatIcon}/>
                            <Form.Control value={snapchat} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Snapchat link" onChange={(e) => { setSnapchat(e.currentTarget.value); }} />
                            <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Snapchat', snapchat);}}>Save</Button>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Image className="socialIconForm my-auto" src={tiktokIcon}/>
                            <Form.Control value={tiktok} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Tiktok link" onChange={(e) => { setTiktok(e.currentTarget.value); }} />
                            <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Tiktok', tiktok);}}>Save</Button>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Image className="socialIconForm my-auto" src={youtubeIcon}/>
                            <Form.Control value={youtube} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Youtube link" onChange={(e) => { setYoutube(e.currentTarget.value); }} />
                            <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Youtube', youtube);}}>Save</Button>
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Image className="socialIconForm my-auto" src={www}/>
                            <Form.Control value={merch} className="my-auto shadow-none text-light socialInput ml-2" placeholder="Merch link" onChange={(e) => { setMerch(e.currentTarget.value); }} />
                            <Button className="btnSocial my-auto ml-2" onClick={() => {updateSocialMedia('Merch', merch);}}>Save</Button>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal centered show={loading} onHide={() => setLoading(false)} className="modalLoading">
                    <LoopCircleLoading color="#FA923A" size={'large'} />  
                </Modal>
            </div>
    );
}

export default Profile;

// src={file ? URL.createObjectURL(file) : image}
