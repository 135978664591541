import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Info = (props) => {
    return (
        <Container fluid style={{height: '80vh'}}>
            <Row className="h-100 mx-0">
                <Col className="my-auto">
                    <Card className="mx-auto cardFailed"
                        style={{ width: '30rem' }}>
                        <Card.Body style={{position: 'relative'}} className="h-100">
                            <h4 className="text-light text-center upperTxt mb-2">{props.location.state && props.location.state.title ? props.location.state.title : 'Page not found'}</h4>
                            <p className="text-light text-center">{props.location.state && props.location.state.msg ? props.location.state.msg : 'Please try later'}</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Info;