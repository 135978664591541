import React, {useContext, useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../Context/UserContext";
import { EventContext } from "../Context/EventContext";
import {showNotif, languages} from '../Component/Utils';
import logoPlain from '../Assets/logo.png';
import close from '../Assets/close.png';
import { buyRequest, saveCamp } from '../Services/user.service'
import { LoopCircleLoading } from 'react-loadingg';
import conf from '../Services/confs';
import saved from '../Assets/saved.png';
import unsave from '../Assets/unsave.png';
import { ContinuousColorLegend } from 'react-vis';

const Detail = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [eventState, setEventState] = useContext(EventContext);
    const [desc, setDesc] = useState([]);
    const [para, setPara] = useState([]);
    const [confirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fav, setFav] = useState(null);

    function displayDesc(val) {
        if (!val)
            return;
        return (
        val.map((el) => {
            return (
                <p className="text-light text-left mb-0 font-weight-light eventSubtitle mx-4">{el.trim()}</p>

            )
        })
        )
    }

    function displayPara() {
        var descTmp = "";
        if (!para)
            return;
        return (
        para.map((el) => {
            descTmp = el ? el.split('^') : "";
            return (
                <p className="text-light text-left mb-0 font-weight-light eventSubtitle mx-4 paraSpace">{displayDesc(descTmp)}</p>
            )
        })
        )
    }

    useEffect(() => {
        if (!userState || !userState.token)
            props.history.push('/login');
        if (!props.location.state) {
            props.history.push('/shop'); 
        }
        setPara(props.location.state.data.attributes.descDash ? props.location.state.data.attributes.descDash.split('|') : "");
        // setDesc(props.location.state.data.attributes.descDash ? props.location.state.data.attributes.descDash.split('^') : "");
        getFav();
    }, [userState]);

    function getFav() {
        fetch(conf.api.urlGBF + "favorites?populate=*&filters[campaign][id][$eq]=" + props.location.state.data.id).then((res) => res.json()).then(async (res) => {
            if (res.data == null) {
              return false;
            }
            setFav(res.data.length > 0 ? res.data[0] : null)
        })
    }

    // function updateFav(val) {
    //     setLoading(true);
    //     fetch(conf.api.urlGBF + "favorites/" + props.location.state.data.id, {
    //         method: 'PUT',
    //         headers: {
    //           Accept: 'application/json',
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             data: {
    //                 "users": val,
    //               }
    //          })
    //         ,
    //     }).then((res) => res.json()).then(async (res) => {
    //         setFav(res.data);
    //         setLoading(false);
    //     })
    // }
    // function createFav(val) {
    //     setLoading(true);
    //     fetch(conf.api.urlGBF + "favorites/", {
    //         method: 'POST',
    //         headers: {
    //           Accept: 'application/json',
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             data: {
    //                 "users": val,
    //                 "campaign": props.location.state.data.id
    //               }
    //          })
    //         ,
    //     }).then((res) => res.json()).then(async (res) => {
    //         setFav(res.data);
    //         setLoading(false);
    //     })
    // }

    // function saveCamp() {
    //     // Update the attribute users (remove user id)
    //     if (fav && fav.attributes.users.includes(userState.user._id) == true) {
    //         let arr = fav.attributes.users;
    //         const index = arr.indexOf(userState.user._id);
    //         if (index > -1) {
    //             arr.splice(index, 1);
    //         }
    //         updateFav(arr);
    //     }
    //     // Update the attribute users (add user id)
    //     else if (fav && fav.attributes.users.includes(userState.user._id) != true) {
    //         fav.attributes.users.push(userState.user._id);
    //         updateFav(fav.attributes.users);

    //     }
    //     //Create e new entry
    //     else {
    //         createFav([userState.user._id])
    //     }
    // }

    async function buyItem() {
        console.log(props.location.state.data.attributes.name);
        // return ;
        setLoading(true);
        let res = await buyRequest(props.location.state.data.attributes.name, props.location.state.data.attributes.credit, userState.token);
        setLoading(false);
        if (res === false) {
            showNotif(true, 'Purchase failed', 'Please check you have enough credits');
        }
        else {
            showNotif(false, 'Purchase successful', 'We have received your request and we will be in touch with you soon.');
            userState.user.credits = res.user.credits;
            setUserState({...userState});
        }
    }

    function getFlag(label) {
        var result = languages.find(obj => {
            return obj.label === label
          });
        if (result)
            return (result.flag);
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col className="mx-2" align="center">
                        {
                            userState &&
                            <>
                                <div className="darkCard">
                                    <div className="profileHeader">
                                        <Image
                                            className="xpBg"
                                            src={props.location.state.data.attributes.img  ? props.location.state.data.attributes.img.data[0].attributes.url  : logoPlain}
                                        />
                                        <Image
                                            className="xpImg"
                                            src={props.location.state.data.attributes.img  ? props.location.state.data.attributes.img.data[0].attributes.url  : logoPlain}
                                            rounded
                                        />
                                    </div>
                                    <h4 className="text-light text-center pb-2 eventTitle">{props.location.state.data.attributes.name}</h4>
                                    <h3 className="">{getFlag(props.location.state.data.attributes.lang)}{getFlag(props.location.state.data.attributes.lang2)}{getFlag(props.location.state.data.attributes.lang3)}</h3>
                                    {
                                        props.location.state.data.attributes.credit <= 0 ?
                                        <p className="eventSubtitle font-weight-bold">FREE</p> :
                                        <p className="eventSubtitle font-weight-bold">{props.location.state.data.attributes.credit} credits</p>
                                    }
                                    <Row className="mt-0 justify-content-center pb-4">
                                        {/* <div className="mb-4">{displayDesc()}</div> */}
                                        <div className="mb-4">{displayPara()}</div>
                                    </Row>
                                    <Image
                                        onClick={() => {props.history.goBack()}}
                                        className="closeBtn mt-4 btnHov"
                                        src={close}
                                    />
                                </div>
                            </>
                        }
                        <Button
                            className="buttonEvent btnHov shadow-none mx-auto mb-4"
                            onClick={() => {
                                // buyRequest(props.location.state.data.name , userState.token);
                                setConfirmation(true);
                            }}>
                             <p className="upperTxt text-light my-auto">{props.location.state.btn}</p>
                        </Button>
                        <Button
                            className="buttonEvent btnHov shadow-none ml-3 mb-4"
                            onClick={() => {
                                saveCamp(fav, userState.user._id,  setLoading, props.location.state.data.id, setFav)
                            }}>
                            <Image className="heartIcon mx-auto btnHov"
                                src={(fav && fav.attributes.users.includes(userState.user._id) == true) ? saved : unsave}
                            />
                             {/* <p className="upperTxt text-light my-auto">{(fav && fav.attributes.users.includes(userState.user._id) == true) ? "Unsave" : "Save"}</p> */}
                        </Button>
                    </Col>
                </Row>
                <Modal centered show={loading} onHide={() => setLoading(false)} className="modalLoading">
                    <LoopCircleLoading color="#FA923A" size={'large'} />  
                </Modal>
                <Modal centered size="lg" show={confirmation} onHide={() => {
                    setConfirmation(false);
                }}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="p-0 mb-3">
                        {
                            props.location.state.data.attributes.credit <= userState.user.credits ?
                            <h5 className="ml-4 text-center ">
                                Are you sure to book this {props.location.state.keyword} for 30 minutes ?
                            </h5>
                            :
                            <h5 className="ml-4 text-center ">
                                You don't have enough credits to book this {props.location.state.keyword}.
                                <br/>
                                Buy credits to continue.
                            </h5>
                        }
                    </Modal.Body>
                    <Modal.Footer className="pt-0">
                        <Button className="buttonDisable" onClick={() => {
                            setConfirmation(false);
                        }}>Cancel</Button>
                        <Button className="button" onClick={() => {
                            if (props.location.state.data.attributes.credit <= userState.user.credits) {
                                setConfirmation(false);
                                buyItem();
                            }
                            else
                                props.history.push('/credit')                       
                        }}>{props.location.state.data.attributes.credit <= userState.user.credits ? 'Buy' : 'Buy credits'}</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
}

export default Detail;