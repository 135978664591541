import {useContext, useEffect, useState} from 'react';
import { UserContext } from "../Context/UserContext";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import credit from '../Assets/credit.png';
import mentor from '../Assets/mentor.png';
import live from '../Assets/live.png';
import building from '../Assets/building.png';
import { getUser } from "../Services/user.service";

const Home = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [welcome, setWelcome] = useState(false);

    useEffect(() => {
        if (!userState || !userState.token || (userState && userState.token && userState.user.acceptedCGU != true)
        ) {
            props.history.push('/login');
        }
        else if (userState && userState.token) {
            getUser(userState.token, userState.user._id)
            .then(res => {
                if (res) {
                    userState.user = res;
                    setUserState({...userState});
                }
                setWelcome(userState.newMember);
            })
        }
    }, []);

    return (
        <Container fluid className="fullScreen">
            <Row className="mx-0 h-100">
                <Col align="center" className="my-auto">
                    <Row className="mx-0 justify-content-center">
                        <Card id="cardPack" className='cardPack mx-4' style={{ width: '10rem', height: '8rem'}}
                                onClick={() => {props.history.push('/mentors')}}>
                            <Card.Body className="h-100 justify-content-center">
                                <Image
                                    className="socialIcon"
                                    src={mentor}
                                />
                                <Card.Text className="text-light text-center upperTxt" id="homeTitle">
                                    Mentors
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card id="cardPack" className='cardPack mx-4' style={{ width: '10rem', height: '8rem'}} onClick={() => {
                            props.history.push('/live')
                            }}>
                            <Card.Body className="justify-content-center">
                                <Image
                                    className="socialIcon"
                                    src={live}
                                />
                                <Card.Text className="text-light text-center upperTxt" id="homeTitle">
                                    Live
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row className="mx-0 justify-content-center">
                        <Card id="cardPack" className='cardPack mx-4' style={{ width: '10rem', height: '8rem'}} onClick={() => {props.history.push('/academy')}}>
                            <Card.Body className="justify-content-center">
                                <Image
                                    className="socialIcon"
                                    src={building}
                                />
                                <Card.Text className="text-light text-center upperTxt" id="homeTitle">
                                    Academy
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card id="cardPack" className='cardPack mx-4' style={{ width: '10rem', height: '8rem'}} onClick={() => {props.history.push('/credit')}}>
                            <Card.Body className="justify-content-center">
                                <Image
                                    className="socialIcon"
                                    src={credit}
                                />
                                <Card.Text className="text-light text-center upperTxt" id="homeTitle">
                                    Credits
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
            </Row>    
            <Modal centered size="lg" show={welcome} onHide={() => {
                    userState.newMember = false;
                    setUserState({...userState});
                    setWelcome(false);
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title className="mx-auto">
                            <p className="modalTitle">WELCOME TO AKIUS EXPERIENCE</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <p className="ml-4 text-center mb-3 mt-2">Enjoy endless options to boost your career right now 🙌<br />
                            It's easy ! Create your first AKIUS Experience and start earning credits 🎁✨<br />
                            then use them to pick the services of your dreams in the AKIUS shop 🍰 🎉</p>
                        <Row className="my-4 mx-0 justify-content-center">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/bPnhgwTIdds?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="pt-0">
                        <Button className="button" onClick={() => {
                            userState.newMember = false;
                            setUserState({...userState});
                            setWelcome(false);
                            // window.fcWidget.open();
                        }}>Let's go</Button>
                    </Modal.Footer>
                </Modal>
        </Container>
    );
}

export default Home;

// Experience, Services, Plan, Help