import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Login from './Container/Login';
import Statistic from './Container/Statistic';
import ArtistsStat from './Container/ArtistsStat';
import ArtistExp from './Container/ArtistExp';
import Detail from './Container/Detail';
import SuperXP from './Container/SuperXP';
import Users from './Container/Users';
import Home from './Container/Home';
import XpValidation from './Container/XpValidation';
import Notifications from './Container/Notifications';
import Credit from './Container/Credit';
import Experiences from './Container/Experiences';
import Academy from './Container/Academy';
import ShopDetail from './Container/ShopDetail';
import Info from './Container/Info';
import Mentoring from './Container/Mentoring';
import Live from './Container/Live';
import ExperienceDetail from './Container/ExperienceDetail';
import EventLive from './Container/EventLive';
import Profile from './Container/Profile';
import UsersProfile from './Container/UsersProfile';
import Favoris from './Container/Favoris';
import Help from './Container/Help';
import Header from './Component/Header'
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { UserProvider } from "./Context/UserContext";
import { EventProvider } from "./Context/EventContext";
import Event from './Container/Event';

const App = () => {

  return (
    <div className="AppBg mx-0">
      <UserProvider>
        <EventProvider>
          <ReactNotification />
          <Router>
            <Header />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/login" exact component={Login} />
              {/* <Route path="/stat" exact component={Statistic} /> */}
              <Route path="/users" exact component={Users} />
              <Route path="/credit" exact component={Credit} />
              <Route path="/academy" exact component={Academy} />
              {/* <Route path="/shopDetail" exact component={ShopDetail} /> */}
              <Route path="/detail" exact component={Detail} />
              <Route path="/Live" exact component={ArtistExp} />
              <Route path="/experiences" exact component={Experiences} />
              {/* <Route path="/exp/:id" exact component={ExperienceDetail} /> */}
              <Route path="/live/:id" exact component={EventLive} />
              <Route path="/event/:id" exact component={Academy} />
              <Route path="/mentors" exact component={Mentoring} />
              <Route path="/favorites" exact component={Favoris} />
              {/* <Route path="/xpValidation" exact component={XpValidation} /> */}
              {/* <Route path="/notifs" exact component={Notifications} /> */}
              <Route path="/profile" exact component={Profile} />
              <Route path="/userProfile" exact component={UsersProfile} />
              <Route path="/artistExp" exact component={ArtistExp} />
              {/* <Route path="/artistStat" exact component={ArtistsStat} /> */}
              {/* <Route path="/events" exact component={ListSuperXP} /> */}
              <Route path="/help" exact component={Help} />
              <Route path="/info" exact component={Info} />
              {/* <Route path="/prestigexp/:id" exact component={SuperXP} /> */}
              <Route path="*" exact component={Info} />
            </Switch>
          </Router>
        </EventProvider>
      </UserProvider>
    </div>
  );
}

export default App;
