
import React, {useState, useEffect} from 'react';

const EventContext = React.createContext();


export const getIntialState = (storageKey) => {
  const savedState = window.localStorage.getItem(storageKey);
  try {
  if (!savedState) {
  return {};
  }
  return JSON.parse(savedState || {});
  } catch (e) {
  return {};
  }
 }

const EventProvider = (props) => {
    const [eventState, setEventState] = useState(getIntialState('eventState'));

    useEffect(() => {
      window.localStorage.setItem('eventState', JSON.stringify(eventState));
    }, [eventState])
  return (
    <EventContext.Provider value={[eventState, setEventState]}>
      {props.children}
    </EventContext.Provider>
  );
}

export { EventContext, EventProvider };