import React, {useContext, useEffect, useState, createRef} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import { UserContext } from "../Context/UserContext";
import { EventContext } from "../Context/EventContext";
import {updateEvent, uploadEventPic, createEvent, deleteEvent} from "../Services/event.service";
import {getUser} from "../Services/user.service";
import logoPlain from '../Assets/logo.png';
import trash from '../Assets/trash.png';
import edit from '../Assets/edit.png';
import copy from '../Assets/copy.png';
import camera from '../Assets/camera.png';
import add from '../Assets/add.png';
import moment from 'moment';
import {showNotif, handleImgChange} from '../Component/Utils';
import { getArtists, getEvents} from "../Services/user.service";
import CropImg from '../Component/CropImg';
import { LoopCircleLoading } from 'react-loadingg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ArtistExp = (props) => {
    const [eventState, setEventState] = useContext(EventContext);
    const [userState, setUserState] = useContext(UserContext);
    const [editEvent, setEditEvent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteEvt, setDeleteEvt] = useState(false);
    const inputOpenFileRef = createRef();
    const [tmpArtist, setTmpArtist] = useState('');
    const [crop, setCrop] = useState({aspect: 1, width: 300});
    const [cropPic, setCropPic] = useState(null);
    const [croppedImage, setCroppedImage] = useState('');
    const [imageRef, setImageRef] = useState('');
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [prestigePrice, setPrestigePrice] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [theEvent, setTheEvent] = useState(null);
    const [incoming, setIncoming] = useState(true);
    const [create, setCreate] = useState(false);
    const [price, setPrice] = useState('ticket_free');
    const [sponsor, setSponsor] = useState('');
    const [duration, setDuration] = useState('60');
    const [aktimeDuration, setAktimeDuration] = useState('0');
    const [privateEvt, setPrivate] = useState(true);
    const [theArtist, setTheArtist] = useState(null);
    // const [welcome, setWelcome] = useState(false);
    const [emails, setEmails] = useState('');
    const [events, setEvents] = useState([]);
    const [search, setSearch] = useState('');

    const fetchEvents = () => {
        
        getArtists(userState.token)
            .then(res => {
                if (res) {
                    setUserState({...userState, artists: res.users});
                    setTmpArtist(res.users);
                }
            });
        getEvents(userState.token)
        .then(res => {
            if (res) {
                console.log(res);
                setEvents(res.eventList)
            }
        });
    }

    const fetchUser = () => {
        getUser(userState.token, userState.user._id)
        .then(res => {
            if (res) {
                userState.user = res;
                setUserState({...userState});
            }
        })
    }

    useEffect(() => {

        if (userState && userState.token) {
                fetchUser();
                fetchEvents();
        }
        else {
            props.history.push('/login'); 
        }
    }, [props.history]);

    useEffect(() => {
        if (croppedImage) {
            setCropPic(false);
            uploadPic();
        }
    }, [croppedImage]);

    useEffect(() => {
        setTimeout(()=> {
            console.log("user ", userState)
            // if (userState && userState.user) {
            //     window.fcWidget.user.setProperties({
            //         externalId: userState.user._id,     // user’s id unique to your system
            //         firstName: userState.user.username,              // user’s first name
            //         lastName: '',
            //         email: userState.user.email,    // user’s email address
            //         phone: userState.user.phoneNumber            // phone number without country code
            //         });
            // }
        }, 3000)
    }, [window]);

    async function uploadPic() {
        if (!croppedImage)
            showNotif(true, 'Error', 'Image not saved.');
        else {
            setLoading(true);
            let res = await uploadEventPic(croppedImage, theEvent._id, userState.token);
            setLoading(false);
            if (res) {
                // setTimeout(() => {
                    console.log(res);
                    let idx = eventState.events.findIndex(elem => elem._id === theEvent._id);
    
                    eventState.events[idx].picture = res;
                    setEventState({...eventState});
                    // setImage(res);
                    setEditEvent(true);
                    setLoading(false);
                // }, 10000);
            }
        }
    }
    
    async function updateEventInfo() {
        if (!date || !name || !description) {
            showNotif(true, 'Error', 'Please enter name, date and description');
            return ;
        }
        
        setLoading(true);
        let body = {name: name, description: description, date: new Date(date).getTime() / 1000}
        let res = await updateEvent(body, theEvent._id, userState.token);
        setLoading(false);
        if (res) {
            let idx = eventState.events.findIndex(elem => elem._id === theEvent._id);

            eventState.events[idx].description = description;
            eventState.events[idx].name = name;
            eventState.events[idx].date = new Date(date).getTime() / 1000;
            setEventState({...eventState});
            setEditEvent(true);
            setLoading(false);
        }
    }
    
    async function createEventBasic() {
        if (!date || !name || !description || !price) {
            showNotif(true, 'Error', 'Please enter name, date, description and ticket price');
            return ;
        }
        setLoading(true);
        let listEmail = (emails && privateEvt) ? emails.split(',') : [];
        let body = {name, description, date: new Date(date).getTime() / 1000, length: duration ? duration : '20',
        aktimeDuration : aktimeDuration ? aktimeDuration : '6', ticket_type: price, isPrivate: privateEvt,
        artistId: userState.artists[theArtist]._id, privateUserList: listEmail, country, city, prestigePrice};
        if (sponsor) body.sponsor = sponsor;
        let res = await createEvent(body, userState.token);
        if (res) {
            userState.user.credits -= 1;
            setUserState({...userState});
            // setEventState({...eventState, evt: res});
            showNotif(false, 'Success', 'Experience has been created.');
            setCreate(false);
            fetchEvents();
        }
        setLoading(false);
    }
    
    async function duplicateEvt(evt) {
        setLoading(true);
        let body = {name: evt.name, description: evt.description, date: evt.date, length: evt.length,
        aktimeDuration: evt.aktimeDuration, ticket_type: 'ticket_free', isPrivate: true, picture: evt.picture,
        artistId: evt.artist, country: evt.country, city: evt.city, prestigePrice: evt.prestigePrice};
        if (sponsor) body.sponsor = sponsor;
        let res = await createEvent(body, userState.token);
        if (res) {
            userState.user.credits -= 1;
            setUserState({...userState});
            showNotif(false, 'Success', 'Experience has been duplicated.');
            fetchEvents();
        }
        setLoading(false);
    }
    
    async function deleteEventReq() {
        setLoading(true);
        let res = await deleteEvent(theEvent._id, userState.token);
        if (res) {
            showNotif(false, 'Success', 'Event has been deleted.');
            setDeleteEvt(false);
            fetchEvents();
        }
        setLoading(false);
    }

    const eventDisplay = (evt) => {
        return (
            <Col key={evt._id} className="mb-2 d-flex cardBodyEvent px-0 flex-column mx-4 mt-4" align="center">
                <Image
                    className="eventBg"
                    src={evt.picture ? evt.picture : logoPlain}
                />
                <div className="eventTop">
                    <p className="eventTitle">{evt.name}</p>
                    <p className="eventSubtitle">{moment(new Date(parseFloat(evt.date) * 1000)).format('MMMM D, yyyy h:mm A')}</p>
                </div>
                <Row className="eventBottom mx-auto">
                    {
                        evt.artist === '603cf202ee78914a0eca6df0' &&
                        <Image
                            className="eventIcon my-auto mx-auto"
                            src={copy}
                            onClick={() => {
                                duplicateEvt(evt);
                            }}
                        />
                    }
                    {
                        evt.artist !== '603cf202ee78914a0eca6df0' &&
                        <Image
                            className="eventIcon my-auto mx-auto"
                            src={edit}
                            onClick={() => {
                                // setTheEvent(evt);
                                // setImage(evt.picture);
                                // setDescription(evt.description);
                                // setName(evt.name);
                                // setDate(new Date(parseFloat(evt.date) * 1000));
                                // setEditEvent(true)
                                if (evt.state == 'OVER') {
                                    showNotif(true, 'Error', "You can't edit anymore because it's finished.");
                                }
                                else {
                                    setEventState({...eventState, evt: evt}); props.history.push('/exp/' + evt._id); 
                                }
                            }}
                        />
                    }
                    {
                       (!evt.picture || evt.placeholdersImg.length < 2 || !evt.length || !(evt.aktimeDuration >= 0)) && evt.state != 'OVER' ?
                       <Button
                            className={evt.artist === '603cf202ee78914a0eca6df0' ? "buttonEvent py-2 pr-3 px-3 shadow-none mx-auto" : "buttonEventRed py-2 pr-3 px-3 shadow-none mx-auto"}
                            onClick={() => {setEventState({...eventState, evt: evt}); props.history.push('/live/' + evt._id); }}>
                                Live
                            {/* {evt.artist === '603cf202ee78914a0eca6df0' ? 'Edit' : 'Complete'} */}
                        </Button> :
                        <Button
                            className="buttonEvent py-2 pr-3 px-3 shadow-none mx-auto"
                            onClick={() => {
                                setEventState({...eventState, evt: evt});
                                if (evt.isValidated &&  evt.state != 'OVER')
                                    props.history.push('/expLive/' + evt._id);
                                else
                                    props.history.push('/exp/' + evt._id);
                            }}>
                            {evt.isValidated && evt.state != 'OVER' ? 'Start' : 'Edit'}
                        </Button>
                    }
                    <Image
                        onClick={() => {setTheEvent(evt);setDeleteEvt(true)}}
                        className="eventIcon my-auto mx-auto"
                        src={trash}
                    />
                </Row>
            </Col>
        )
    }

    if (!userState)
        return <></>;

    console.log(events);
    return (
        <>
        {
            userState && userState.user ?
            <div>
               
                {/* <Container> */}
                    <Row className="mx-0">
                        <Button className={`mr-2 ml-4 shadow-none ${incoming ? 'buttonEvent' : 'buttonDisableBlue'}`} onClick={() => {setIncoming(true)}}>UPCOMING</Button>
                        <Button className={`mr-2 shadow-none ${!incoming ? 'buttonEvent' : 'buttonDisableBlue'}`} onClick={() => {setIncoming(false)}}>PAST</Button>
                    </Row>
                    <Row  className="pt-4 mx-auto">
                        {events.map((evt) => {
                            console.log(evt);
                            let eventDate = new Date(parseFloat(evt.date) * 1000);
                            if (incoming && evt.state !== 'OVER' && eventDate > new Date().setMinutes(new Date().getMinutes() - 30)){
                                return (eventDisplay(evt));
                            }
                            else if (!incoming && (evt.state === 'OVER'|| eventDate < new Date().setMinutes(new Date().getMinutes() - 30)))
                                return eventDisplay(evt);
                        })}
                        {
                        userState.artists && theArtist === null && userState.artists.map((el) => {
                            return (
                                el.artist.events.map((evt) => {
                                    let eventDate = new Date(parseFloat(evt.date) * 1000);
                                    if (incoming && evt.state !== 'OVER' && eventDate > new Date().setMinutes(new Date().getMinutes() - 30)){
                                        return (eventDisplay(evt));
                                    }
                                    else if (!incoming && (evt.state === 'OVER'|| eventDate < new Date().setMinutes(new Date().getMinutes() - 30)))
                                        return eventDisplay(evt);
                                    return (<></>);
                                    })
                                )
                            })
                        }
                    </Row>
                    <Modal centered show={editEvent && !loading} onHide={() => setEditEvent(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                            Edit your event
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row className="mb-4">
                                <Form.File
                                    ref={inputOpenFileRef}
                                    className="mt-2"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    type="file"
                                    onChange={(evt) => {handleImgChange(evt, setFile, null, setCropPic)}}
                                    />
                                    {
                                        (image || croppedImage) ? 
                                        <Image
                                            className="editPic mx-auto"
                                            src={croppedImage ? URL.createObjectURL(croppedImage) : image}
                                            onClick={() => {inputOpenFileRef?.current.click()}}
                                            rounded
                                        />
                                        :
                                        <div className="iconBg mx-auto" onClick={() => {inputOpenFileRef?.current.click()}}>
                                            <Image
                                                className="socialIcon"
                                                src={camera}
                                            />
                                        </div>
                                    }
                                </Row>
                                <Form.Control value={name} className="shadow-none text-light" placeholder="Name" onChange={(e) => { setName(e.currentTarget.value); }} />
                                <DatePicker selected={date} className="dateInput shadow-none text-light"
                                    onChange={val => setDate(val)} timeFormat="HH:mm" showTimeSelect placeholderText="Date"
                                />
                                <Form.Control value={description}  as="textarea" rows={3} className="shadow-none text-light"
                                    placeholder="Description" onChange={(e) => { setDescription(e.currentTarget.value); }} />

                                    
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="button" onClick={() => {
                                updateEventInfo();
                            }}>Save</Button>
                        </Modal.Footer>
                    </Modal>
                    {CropImg(cropPic, setCroppedImage, setCropPic, file, setCrop, crop, setImageRef, imageRef)}
                    <Modal centered show={loading} onHide={() => setLoading(false)} className="modalLoading">
                        <LoopCircleLoading color="#FA923A" size={'large'} />  
                    </Modal>
                    <Modal centered show={create && !loading} onHide={() => setCreate(false)} >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                            Experience informations
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Control value={name} className="shadow-none text-light" placeholder="Name" onChange={(e) => { setName(e.currentTarget.value); }} />
                                <DatePicker minDate={new Date()} selected={date} className="dateInput shadow-none text-light"
                                    onChange={val => setDate(val)} dateFormat="MMMM d, yyyy h:mm aa" timeFormat="HH:mm" showTimeSelect placeholderText="Date"
                                />
                                {/* <Form.Control value={duration} type={'number'} className="shadow-none text-light"
                                    placeholder="Live duration (default 20 minutes)" onChange={(e) => { setDuration(e.currentTarget.value); }} />
                                <Form.Control value={aktimeDuration} type={'number'} className="shadow-none text-light"
                                    placeholder="Meet & Greet duration (default 6 minutes)" onChange={(e) => { setAktimeDuration(e.currentTarget.value); }} /> */}
                                <Form.Control value={description} as="textarea" rows={3} className="shadow-none text-light"
                                    placeholder="Description" onChange={(e) => { setDescription(e.currentTarget.value); }} />
                                <Form.Row>
                                    {/* <Col className="d-flex justify-content-center col-md-auto">
                                        <Form.Control value={sponsor}  className="shadow-none text-light"
                                            placeholder="Sponsor" onChange={(e) => { setSponsor(e.currentTarget.value); }} />
                                    </Col> */}
                                    {/* <Col className="d-flex justify-content-center col-md-auto">
                                        <Dropdown as={ButtonGroup} className="shadow-none">
                                            <Dropdown.Toggle>{!price ? 'Price' : (price === 'ticket_free' ? 'Free' : (price === 'ticket_test' ? 'Notify Me' : (parseFloat(price) + 'euro')))}</Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdownMenu">
                                                <Dropdown.Item eventKey="1" onClick={()=> setPrice('ticket_free')}>Free</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" onClick={()=> setPrice('ticket_test')}>Notify me</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col> */}
                                    {/* <Col align="center" className="d-flex justify-content-center col-md-auto align-self-end">
                                        <p className={`px-2 py-1 pillTxtEdit mt-auto ml-1 text-light mb-1 ${privateEvt ? 'pillTxtEdit' : 'pillTxtEditDis'}`} onClick={() => {setPrivate(true)}}>Private</p>
                                    </Col>
                                    <Col align="center" className="d-flex justify-content-center col-md-auto align-self-end">
                                        <p className={`px-2 py-1 pillTxtEdit mt-auto ml-1 text-light mb-1 ${!privateEvt ? 'badgeBlue' : 'pillTxtEditDis'}`} onClick={() => {setPrivate(false)}}>Public</p>
                                    </Col> */}
                                </Form.Row>
                                {
                                    <Form.Control value={emails} className="shadow-none text-light"
                                        placeholder="List of email seperate by ," onChange={(e) => { setEmails(e.currentTarget.value); }} />
                                }
                                {
                                    (theArtist && (userState.artists[theArtist]._id === "603cf202ee78914a0eca6df0" || userState.artists[theArtist]._id === "607546636c6ef11eacd3d0d7" ||
                                    userState.artists[theArtist]._id === "607545f26c6ef11eacd3d0d1" || userState.artists[theArtist]._id === "6075461c6c6ef11eacd3d0d4")) ?
                                    <>
                                        <Form.Control value={country} className="shadow-none text-light"
                                            placeholder="Country" onChange={(e) => { setCountry(e.currentTarget.value); }} />
                                        <Form.Control value={city} className="shadow-none text-light"
                                            placeholder="City" onChange={(e) => { setCity(e.currentTarget.value); }} />
                                        <Form.Control value={prestigePrice} type={'number'} className="shadow-none text-light"
                                            placeholder="Number of credits" onChange={(e) => { setPrestigePrice(e.currentTarget.value); }} />
                                    </>
                                    :
                                    <></>
                                }
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="button shadow-none" onClick={() => {
                                if (!duration)
                                    setDuration(20);
                                if (!aktimeDuration)
                                    setAktimeDuration(6);
                                createEventBasic();
                            }}>Create</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal centered show={deleteEvt} onHide={() => {setDeleteEvt(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Are you sure to delete this event ?
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <p className="text-center">{theEvent?.name}</p>
                        </Modal.Body>
                        <Modal.Footer className="pt-0">
                            <Button className="button" onClick={() => {deleteEventReq()}}>Delete</Button>
                        </Modal.Footer>
                    </Modal>
                    {/* <Modal centered size="lg" show={welcome} onHide={() => {
                        userState.newMember = false;
                        setUserState({...userState});
                        setWelcome(false);
                    }}>
                        <Modal.Header closeButton>
                            <Modal.Title className="mx-auto">
                                <p className="modalTitle">WELCOME TO AKIUS EXPERIENCE</p>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            <p className="ml-4 text-center mb-3 mt-2">Welcome to the AKIUS experience ! Now enjoy endless options to grow faster 🙌<br />
                            Go to Plan : Activate your 5 livestream experiences and register for any prestigious event 🎉</p>
                        </Modal.Body>
                        <Modal.Footer className="pt-0">
                            <Button className="button" onClick={() => {
                                userState.newMember = false;
                                setUserState({...userState});
                                setWelcome(false);
                            }}>Let's go</Button>
                        </Modal.Footer>
                    </Modal> */}
                {/* </Container> */}
            </div>
            : null
        }
        </>
    );
}

export default ArtistExp;