import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { UserContext } from "../Context/UserContext";
import logoPlain from '../Assets/logo.png';
import {languages, listIndustry} from '../Component/Utils';
import { LoopCircleLoading } from 'react-loadingg';
import "react-datepicker/dist/react-datepicker.css";
import conf from '../Services/confs';
import saved from '../Assets/saved.png';
import unsave from '../Assets/unsave.png';
import { saveCamp } from '../Services/user.service'
import moment from 'moment';

const Favoris = (props) => {
    const [userState, setUserState] = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [mentors, setMentors] = useState([]);
    const [TmpRes, setTmpRes] = useState([]);
    const [industry, setIndustry] = useState()
    const [lang, setLang] = useState()
    const [favs, setFavs] = useState([]);

    useEffect(() => {
        if (userState && userState.token && userState.user.acceptedCGU == true) {
            getFavorites();
        }
        else {
            props.history.push('/login'); 
        }
    }, [props.history]);

    function getFavorites() {
        fetch(conf.api.urlGBF + "favorites?populate[campaign][populate]=*&filters[users][$containsi]=" + userState.user._id + "&pagination[pageSize]=1000").then((res) => res.json()).then(async (res) => {
            if (res.data == null) {
                return false;
            }
            setFavs(res.data);
            let arr = [];
            for (let i = 0; i < res.data.length; i++) {
                arr.push(res.data[i].attributes.campaign.data)
            }
            setMentors(arr);
            setTmpRes(arr);
        })
    }

    useEffect(() => {
        setTimeout(()=> {
        }, 3000)
    }, [window]);

    const mentorDisplay = (elem) => {
        if (elem) {
            if ((!lang || (elem.attributes.lang == lang || elem.attributes.lang2 == lang || elem.attributes.lang3 == lang)) &&
                ((industry && elem.attributes.industries == industry) || ! industry))
            return (
                <Col className="mb-2 d-flex cardBodyEvent px-0 flex-column mx-4 mt-4" align="center">
                    <Image
                        className="eventBg"
                        style={{cursor: 'pointer'}}
                        src={elem.attributes.img ? elem.attributes.img.data[0].attributes.url : logoPlain}
                        onClick={() => {
                            props.history.push({
                                pathname: '/detail',
                                state: { data: elem, btn: 'Book' }
                            });
                        }}
                    />
                    <div className="eventTop">
                        <h5 className="eventTitleMedium">{elem.attributes.name}</h5>
                        {
                            elem.attributes.type == "event" ?
                            <p className="smTitle">{moment(new Date(elem.attributes.date)).format('MMMM D, yyyy')}</p> :
                            <p className="smTitle">{elem.attributes.sub}</p>
                        }
                    </div>
                    <Row className="xpBottom mx-auto">
                        <Button
                            className="buttonEvent py-2 pr-3 px-3 shadow-none mx-auto upperTxt text-light font-weight-bold"
                            onClick={() => {
                                props.history.push({
                                    pathname: '/detail',
                                    state: { data: elem, btn: 'Book'  }
                                });
                            }}>
                            {'Details'}
                        </Button>
                        <Button
                            className="buttonEvent py-2 pr-3 px-3 shadow-none mx-auto upperTxt text-light font-weight-bold"
                            onClick={() => {
                                let fav = favs.find(el => el.attributes.campaign.data.id === elem.id);
                                saveCamp(fav, userState.user._id, setLoading, fav ? fav.id : elem.id, undefined, getFavorites)
                            }}>
                            <Image
                                className="heartIcon mx-auto btnHov"
                                src={favs.find(el => (el.attributes.campaign.data && el.attributes.campaign.data.id) === elem.id) ? saved : unsave}
                            />
                        </Button>
                    </Row>
                </Col>
            )
        }
    }

    function seeMore(elem) {
        props.history.push({
            pathname: '/shopDetail',
            state: { data: elem }
        });
    }

    return (
        <div>
            <Container>
                <Row className="mt-4 justify-content-center">
                    <Form inline className="mb-1 mr-2">
                        <FormControl type="text" placeholder="Search"
                            value={search}
                            className="inputSearch shadow-none m-0 ml-4"
                            onChange={(e) => {
                                setSearch(e.currentTarget.value);
                                if (e.currentTarget.value && e.currentTarget.value.length > 0) {
                                    let idx = -1;
                                    let arr = [];
                                    for (let i = 0; i < TmpRes.length; i++) {
                                        idx = TmpRes[i].attributes.hashtags.findIndex(el => {
                                            return el.toLowerCase().includes(e.currentTarget.value.toLowerCase())
                                        });
                                        if (idx >= 0)
                                            arr.push(TmpRes[i])
                                    }
                                    setMentors(arr);
                                }
                                else {
                                    if (mentors.length !== TmpRes)
                                    setMentors(TmpRes);
                                }
                            }} />
                    </Form>
                    <Col md={4}>
                        <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                            <Dropdown.Toggle>{industry ? industry : "Select Industry"}</Dropdown.Toggle>
                            <Dropdown.Menu className="countryDrop">
                                {
                                    listIndustry.map((item, id) => {
                                        return (
                                            <Dropdown.Item className={industry == item ? "dropSelect" : ""} eventKey={id} onClick={()=> setIndustry(item != industry ? item : "")}>{item}</Dropdown.Item>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={3}>
                        <Dropdown as={ButtonGroup} className="shadow-none mb-3 w-100">
                            <Dropdown.Toggle>{lang ? lang : "Select Language"}</Dropdown.Toggle>
                            <Dropdown.Menu className="dropdownMenu">
                                {
                                    languages.map((item, id) => {
                                        return (
                                            <Dropdown.Item className={lang == item.label ? "dropSelect" : ""} eventKey={id} onClick={()=> setLang(item.label != lang ? item.label : "")}>{item.label}</Dropdown.Item>
                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Button
                            className="button mt-3 py-1 px-2 shadow-none mx-auto"
                            onClick={() => {
                                setLang("");
                                setIndustry("");
                                setMentors(TmpRes);
                                setSearch("");
                            }}>
                            <p className="text-light my-auto smTxt">Reset</p>
                        </Button>
                    </Col>
                </Row>
                <Row  className="pt-4 mx-auto">
                    {mentors.map((elem) => {
                        return (mentorDisplay(elem));
                        // let mentorDate = new Date(elem.attributes.date);
                        // let today = new Date();
                        // if (incoming && mentorDate >= today){
                        //     return (mentorDisplay(elem));
                        // }
                        // else if (!incoming && (mentorDate < today))
                        //     return mentorDisplay(elem);
                        // return (<></>)
                    })}
                </Row>
                <Modal centered show={loading} onHide={() => setLoading(false)} className="modalLoading">
                    <LoopCircleLoading color="#FA923A" size={'large'} />  
                </Modal>
            </Container>
        </div>
    );
}

export default Favoris;