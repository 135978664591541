import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

function CropImg(cropPic, setCroppedImage, setCropPic, file, setCrop, crop, setImageRef, imageRef) {

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
                
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        setCroppedImage(new File([u8arr], filename, {type:mime}));
        setCropPic(false);
        // uploadPic();
    }
        
    const getCroppedImg = (image, crop) => {

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )
    
        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                dataURLtoFile(reader.result, 'cropped.jpg');
            }
        })
    }

    const onImageLoaded = (image) => {
        setImageRef(image);
    }
    
    const onCropChange = (crop) => {
        setCrop(crop)
    }
    
    const onCropComplete = (crop) => {
        // if (imageRef && crop && crop.width && crop.height) {
        //     getCroppedImg(imageRef, crop);
        // }
    }

    return (
        <Modal centered show={cropPic} onHide={() => {setCroppedImage(null); setCropPic(false)}}>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Crop your picture
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Row className="mb-4">
                    {
                        file &&
                        <ReactCrop
                            src={file}
                            crop={crop}
                            onImageLoaded={onImageLoaded}
                            onComplete={onCropComplete}
                            onChange={onCropChange}
                        />
                    }
                </Row>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button className="button" onClick={() => {getCroppedImg(imageRef, crop);}}>Crop</Button>
        </Modal.Footer>
    </Modal>
    )
}

export default CropImg;

// minWidth={300}
// minHeight={300}