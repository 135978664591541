import { ContinuousColorLegend } from 'react-vis';
import {showNotif} from '../Component/Utils';
import conf from './confs';

export const uploadProfilePic = async (file, token, artistId) => {
  const data = new FormData();
  data.append('file', file);
  const response = await fetch(conf.api.url + 'users/artist/' + artistId + '/picture/', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: data
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Image not saved.')
      return (false);
  }
  else {
      const url = await response.json();
      return url;
  }
};

export const updateProfile = async (description, name, id, token, password) => {
    const response = await fetch(conf.api.url + 'users/artist/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          description,
          password,
        }),
      });
    if (response.status >= 400) {
        showNotif(true, 'Error', 'Profile information not saved.')
        return (false);
    }
    else {
        console.log("respoonse", response)
        return true;
    }
};

export const updateQcmInfo = async (country, turnover, language, employees, business, industry, minCredit, maxCredit, motivation, isFirstLogin, id, token) => {
    const response = await fetch(conf.api.url + 'users/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country, turnover, language, employees, business, industry, minCredit, maxCredit, motivation, isFirstLogin
        }),
      });
    if (response.status >= 400) {
        console.log("response", response.body)
        showNotif(true, 'Error', 'Profile information not saved.')
        return (false);
    }
    else {
        console.log("respoonse", response)
        return true;
    }
};

export const updateAcceptInfo = async (acceptedCGU, id, token) => {
    const response = await fetch(conf.api.url + 'users/' + id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          acceptedCGU
        }),
      });
    if (response.status >= 400) {
        console.log("response", response.body)
        showNotif(true, 'Error', 'Profile information not saved.')
        return (false);
    }
    else {
        console.log("respoonse", response)
        return true;
    }
};

export const addSocial = async (name, url, token, artistId) => {
    const response = await fetch(conf.api.url + 'users/artist/' + artistId + '/socialMedia/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          url: url
        }),
      });
    if (response.status >= 400) {
        showNotif(true, "Error", 'Social media not added.')
        return (false);
    }
    else {
        return true;
    }
};

export const updateSocial = async (name, url, id, token, artistId) => {
    const response = await fetch(conf.api.url + 'users/artist/' + artistId + '/socialMedia/' + id, {
        method: url ? 'PUT' : 'DELETE',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          url: url
        }),
      });
    if (response.status >= 400) {
        showNotif(true, 'Error', 'Social media not saved.')
        return (false);
    }
    else {
        return true;
    }
};

export const deleteUser = async (id, token) => {
  const response = await fetch(conf.api.url + 'users/artist/' + id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', "User has not been deleted.")
      return (false);
  }
  else {
      return true;
  }
};

export const getUser = async (token, id) => {
    const response = await fetch(conf.api.url + 'users/' + id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    if (response.status >= 400) {
        showNotif(true, 'Error', 'Did not get user.')
        return (false);
    }
    else {
      const json = await response.json();
      return json;
    }
};

export const getStats = async (token) => {
    const response = await fetch(conf.api.url + 'users/artist/stats', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    if (response.status >= 400) {
        showNotif(true, 'Error', 'Did not get statistic.')
        return (false);
    }
    else {
      const json = await response.json();
      return json;
    }
};

export const getUserStats = async (token, id) => {
    const response = await fetch(conf.api.url + 'users/artist/stats?userId=' + id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    if (response.status >= 400) {
        showNotif(true, 'Error', 'Did not get statistic.')
        return (false);
    }
    else {
      const json = await response.json();
      return json;
    }
};

export const getArtists = async (token) => {
    const response = await fetch(conf.api.url + 'label/artists', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    if (response.status >= 400) {
        showNotif(true, 'Error', 'Did not get artists.')
        return (false);
    }
    else {
      const json = await response.json();
      return json;
    }
};

export const getEvents = async (token) => {
  const response = await fetch(conf.api.url + 'events', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  if (response.status >= 400) {
      showNotif(true, 'Error', 'Did not get events.')
      return (false);
  }
  else {
    const json = await response.json();
    return json;
  }
};

export const buyRequest = async (id, credits, token) => {
    const response = await fetch(conf.api.url + 'buy_request', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            eventId: id,
            credits: credits
          }),
      });
    if (response.status >= 400) {
        return (false);
    }
    else {
      const json = await response.json();
      return json;
    }
};


export const buyPlan = async (token, nb) => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(conf.api.url + 'buy_plan', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        plan_nb: nb,
      }),
    });
    
  if (response.status >= 400) {
      reject(false);
  }
  else {
    const json = await response.json();
    resolve(json);
  }
  })
};

function updateFav(val, setLoading, campId, setFav, getFavorites) {
  setLoading(true);
  fetch(conf.api.urlGBF + "favorites/" + campId, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          data: {
              "users": val,
            }
       })
      ,
  }).then((res) => res.json()).then(async (res) => {
      if (setFav)
        setFav(res.data);
      if (getFavorites)
        getFavorites();
      setLoading(false);
  })
}

export function createFav(val, campId, setLoading, setFav, getFavorites) {
  setLoading(true);
  fetch(conf.api.urlGBF + "favorites?populate=*&filters[campaign][id][$eq]=" + campId).then((res) => res.json()).then(async (res) => {
      if (res.data == null || res.data.length <= 0) {
        fetch(conf.api.urlGBF + "favorites/", {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              data: {
                  "users": [val],
                  "campaign": campId
                }
           })
          ,
        }).then((res) => res.json()).then(async (res) => {
            if (setFav)
              setFav(res.data);
            if (getFavorites)
              getFavorites();
            setLoading(false);
        })
      }
      else {
        let arr = res.data[0].attributes.users;
        arr.push(val)
        fetch(conf.api.urlGBF + "favorites/" + res.data[0].id, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              data: {
                  "users": arr,
                }
           })
          ,
      }).then((res) => res.json()).then(async (res) => {
          if (setFav)
            setFav(res.data);
          if (getFavorites)
            getFavorites();
          setLoading(false);
      })
      }
      
  })
}

export function saveCamp(fav, id, setLoading, campId, setFav, getFavorites) {
  // Update the attribute users (remove user id)
  if (fav && fav.attributes.users.includes(id) == true) {
      let arr = fav.attributes.users;
      const index = arr.indexOf(id);
      if (index > -1) {
          arr.splice(index, 1);
      }
      updateFav(arr, setLoading, fav.id, setFav, getFavorites);
  }
  // Update the attribute users (add user id)
  else if (fav && fav.attributes.users.includes(id) != true) {
      fav.attributes.users.push(id);
      updateFav(fav.attributes.users, setLoading, fav.id, setFav, getFavorites);

  }
  //Create e new entry
  else {
      createFav(id, campId, setLoading, setFav, getFavorites)
  }
}