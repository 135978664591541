import React, {useContext} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Banner from '../Assets/logo.png';
import BannerLogin from '../Assets/logo.png';

import { withRouter } from "react-router";
import { UserContext } from "../Context/UserContext";

function Header(props) {
  const [userState] = useContext(UserContext);
  let current = props.history.location.pathname;

  if (userState && userState.token && userState.user.acceptedCGU == true)
    return (
      <Navbar className="box navBg mb-3" variant="dark">
        <Navbar.Brand onClick={() => {props.history.push('/')}}>
          <img
            alt=""
            src={Banner}
            width="50"
            height="50"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Nav className="ml-auto stroke">
          <Nav.Link className={current === '/' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/')}}>Home</Nav.Link>
          {
            // userState.user._id === '603e08bef8872e1df05b3ba9' && <Nav.Link className={current === '/xpValidation' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/xpValidation')}}>XP validation</Nav.Link>
          }
          {
            // userState.user._id === '603e08bef8872e1df05b3ba9' && <Nav.Link className={current === '/notifs' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/notifs')}}>Notifications</Nav.Link>
          }
          {/* <Nav.Link className={current === '/events' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/events')}}>Events</Nav.Link> */}
          {
            // userState.user.isLabel == true ?
            // <Nav.Link className={current === '/artistExp' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/artistExp')}}>Experiences</Nav.Link>
            // :
            <Nav.Link className={current === '/mentors' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/mentors')}}>Mentors</Nav.Link>
          }
            <Nav.Link className={current === '/live' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/live')}}>Live</Nav.Link>
          {
            userState.user.isLabel && <Nav.Link className={current === '/users' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/users')}}>Users</Nav.Link>
          }
          {
            //  userState.user._id === '603e08bef8872e1df05b3ba9' &&  <Nav.Link className={current === '/artistStat' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/artistStat')}}>Statistics</Nav.Link>
          }
          {/* {
            userState.user.isLabel ?
            <Nav.Link className={current === '/artistStat' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/artistStat')}}>Statistics</Nav.Link>
            :
            <Nav.Link className={current === '/stat' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/stat')}}>Statistics</Nav.Link>
          } */}
          <Nav.Link className={current === '/academy' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/academy')}}>Academy</Nav.Link>
          <Nav.Link className={current === '/favorites' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/favorites')}}>Favorites</Nav.Link>
          <Nav.Link className={current === '/profile' ? 'menuItem activeColor' : 'menuItem'} onClick={() => {props.history.push('/profile')}}>Profile</Nav.Link>
          <p className='menuCredit my-auto font-weight-bold text-uppercase btnHov' onClick={() => {props.history.push('/credit')}}>{userState.user && userState.user.credits ? userState.user.credits : 0} {userState.user && userState.user.credits && userState.user.credits > 0 ? 'Credits' : 'Credit'}</p>
        </Nav>
      </Navbar>
    );
    else
    return (
      <img className="logoLogin ml-4" alt="" src={BannerLogin} width="160"/>
  );
}

export default withRouter(Header);
